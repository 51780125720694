import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Loader, PrimaryButton } from "../../../components/common";
import { RxCross1 } from "react-icons/rx";
import {
  Utils,
  constants,
  DeviceStatusEnum,
  DeviceTypeEnum,
} from "../../../helpers";
import { Tab, Tabs } from "react-bootstrap";
import { mqttPublish } from "../../../utils/connection/mqttConnection";
import { useLoggedInUserData } from "../../../store/LoggedInAccountStore";
import { devicesMQTTStore } from "../../../store/DevicesMQTTStore";
import { ReactComponent as RefreshIcon } from "../../../assets/images/refresh.svg";
import { ReactComponent as InfoCircleIcon } from "../../../assets/images/Info-circle.svg";
import { ReactComponent as NoDeviceIcon } from "../../../assets/images/no-device.svg";
import { ReactComponent as ExclaimationIcon } from "../../../assets/images/expired.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/images/icons/errorIcon.svg";
import { TickOutlined } from "../../../assets/images";
import MoveDeviceControl from "./NVR/MoveDeviceControl";
import AddAndUpdateDeviceControl from "./NVR/AddAndUpdateDeviceControl";
import NotAuthenticatedControl from "./NVR/NotAuthenticatedControl";
import ReClaimDeviceControl from "./NVR/ReClaimDeviceControl";
import {
  getNVRDeviceData,
  setAddedAndClaimedDeviceId,
  getAddedAndClaimedDeviceId,
} from "../../../store/reducers/NVRDeviceReducer";
import {
  getAllMqttDataFromResponse,
  resetAllMqttDataFromResponse,
} from "../../../store/reducers/AccountReducer";
import { BsInfinity } from "react-icons/bs";
import {
  getAllScannedDevices,
  getDevicesListOfCurrOrg,
  getNVRScannedDevices,
  setAllScannedDevices,
  setDevicesListOfCurrOrg,
  setNVRScannedDevices,
} from "../../../store/NVRDeviceStoreIDB";
import { observerInstance } from "../../../store/indexDB/observer";
import useDebouncedCallback from "../../../hooks/useDebouncedCallback";
import { getOrgInfo } from "../../../store/reducers/OrganizationsReducer";

const filterTabs = ["All", "Added", "Unauthenticated", "Already Assigned"];

const DiscoveredDevices = ({ hideModalHandler }) => {
  const expireTime = 60;
  const dispatch = useDispatch();
  const resourceList = Utils.getDeviceSettingResource(11);
  const nvrDeviceData = useSelector(getNVRDeviceData);
  const orgInfo = useSelector(getOrgInfo);
  const addedAndClaimedDeviceId = useSelector(getAddedAndClaimedDeviceId);
  const [devicesListOfCurrOrgList, setDevicesListOfCurrOrgList] = useState([]);
  const maxDeviceCount = nvrDeviceData?.channelCount || 0;
  const [nvrScannedDevicesList, setNVRScannedDevicesList] = useState([]);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const { getState } = devicesMQTTStore;
  const state = getState();
  const sessionId = state.getSessionId();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const tid = Math.floor(new Date().getTime() / 1000.0);
  const accountId = loggedInUserData.accountId;
  const hubId = nvrDeviceData?.gatewayId;
  const appTopic = `a/notify/${hubId}`;
  const [showLoader, setShowLoader] = useState(true);
  const [loaderForSelectedDevice, setLoaderForSelectedDevice] = useState(false);
  const [scannedDevices, setScannedDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const addedDeviceCount = useRef(0);
  const isReInitiateScanNetworkReq = useRef(true);
  const [selectedTab, setSelectedTab] = useState([]);
  const [selectedTabKey, setSelectedTabKey] = useState(filterTabs[0]);
  const [actualDevices, setActualDevices] = useState([]);
  const [isReloadSelectedDevice, setIsReloadSelectedDevice] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isFetchScannedDevices, setIsFetchScannedDevices] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [allScannedDevicesList, setAllScannedDevicesList] = useState();
  const [showLoaderForNotAuth, setShowLoaderForNotAuth] = useState(false);
  const hideLoaderRef = useRef(null);
  const [hasDevices, setHasDevices] = useState(null);
  const [showMaxLimitMsg, setShowMaxLimitMsg] = useState(true);

  const loadAllScannedDevices = useCallback(async () => {
    const allDevices = await getAllScannedDevices();
    setAllScannedDevicesList(allDevices || []);
  }, []);

  const loadNVRScannedDevices = useCallback(async () => {
    const allDevices = await getNVRScannedDevices();
    setNVRScannedDevicesList(allDevices || []);
  }, []);

  const loadDevicesListOfCurrOrgList = useCallback(async () => {
    const allDevices = await getDevicesListOfCurrOrg();
    setDevicesListOfCurrOrgList(allDevices || []);
  }, []);

  const debouncedLoadAllScannedDevices = useDebouncedCallback(
    loadAllScannedDevices,
    1000
  );
  const debouncedLoadNVRScannedDevices = useDebouncedCallback(
    loadNVRScannedDevices,
    1000
  );
  const debouncedLoadDevicesListOfCurrOrgList = useDebouncedCallback(
    loadDevicesListOfCurrOrgList,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === "allScannedDevices") {
        await debouncedLoadAllScannedDevices();
      }
      if (data.key === "nvrScannedDevices") {
        await debouncedLoadNVRScannedDevices();
      }
      if (data.key === "devicesListOfCurrOrg") {
        await debouncedLoadDevicesListOfCurrOrgList();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadAllScannedDevices();
    debouncedLoadNVRScannedDevices();
    debouncedLoadDevicesListOfCurrOrgList();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [
    debouncedLoadAllScannedDevices,
    debouncedLoadNVRScannedDevices,
    debouncedLoadDevicesListOfCurrOrgList,
  ]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1);
      } else {
        setIsFetchScannedDevices(false);
        clearInterval(timerInterval);
      }
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, [remainingTime]);

  const findMissingDevices = (devicesList) => {
    const missingDevices = [];
    devicesList.forEach((item) => {
      const existsInList = nvrScannedDevicesList.some(
        (childItem) =>
          childItem.macAddress &&
          item.macAddress &&
          childItem.macAddress.toUpperCase() ===
            item.macAddress.toUpperCase() &&
          (childItem.serialNo === item.serialNo ||
            childItem.serialNumber === item.serialNo)
      );
      if (!existsInList) {
        missingDevices.push({
          ...item,
          serialNumber: item.serialNo, // Key is different in scan network device mqtt response.
          ipAddress: item.properties?.["ip-address"], // IP Address is in properties of a device
          port: "", // This is missing in device
          authStatus: true, // Added channel always authenticated because after authentication it can be added
        });
      }
    });
    return missingDevices;
  };

  useEffect(() => {
    if (nvrScannedDevicesList && nvrScannedDevicesList?.length > 0) {
      const alreadyAddedDevices = nvrDeviceData?.childDevices;
      const missingDevices =
        alreadyAddedDevices?.length > 0
          ? findMissingDevices(alreadyAddedDevices)
          : [];
      const allDevices = [...nvrScannedDevicesList, ...missingDevices];
      const updatedDevices = allDevices?.map((item) => ({
        ...item,
        ...getDeviceProperties(item),
      }));
      const devicesWithStatus = updatedDevices?.filter(
        (item) => item.deviceAuthStatus
      );
      setActualDevices(devicesWithStatus);
      const filteredDevicesByTab =
        selectedTab.length > 0
          ? devicesWithStatus?.filter((item) =>
              selectedTab.includes(item.deviceAuthStatus)
            )
          : devicesWithStatus;
      setScannedDevices(filteredDevicesByTab);
      if (filteredDevicesByTab?.length !== scannedDevices?.length) {
        setSelectedDevice(filteredDevicesByTab?.[0]);
      } else {
        const updatedDevice = updatedDevices?.find(
          (item) => item?.channel === selectedDevice?.channel
        );
        setSelectedDevice(selectedDevice ? updatedDevice : updatedDevices?.[0]);
      }
      const deviceCount =
        devicesWithStatus?.filter(
          (item) =>
            item.deviceAuthStatus === DeviceStatusEnum.CON_OFFLINE ||
            item.deviceAuthStatus === DeviceStatusEnum.ADDED_N_CLAIMED ||
            item.deviceAuthStatus === DeviceStatusEnum.ADDED_NOT_CLAIMED
        )?.length || 0;
      addedDeviceCount.current = deviceCount;
    }
  }, [devicesListOfCurrOrgList, nvrScannedDevicesList, selectedTab]);

  useEffect(() => {
    if (
      selectedTabKey === filterTabs[0] &&
      addedAndClaimedDeviceId &&
      scannedDevices.length > 0
    ) {
      handleDeviceClickByDeviceId(addedAndClaimedDeviceId);
    }
  }, [scannedDevices]);

  useEffect(() => {
    if (orgInfo?.orgId) {
      getPublishRequest();
      fetchDeviceListForOrg();
    }
  }, [isReInitiateScanNetworkReq.current, orgInfo?.orgId]);

  useEffect(() => {
    handleDeviceClick(selectedDevice?.channel);
  }, [isReloadSelectedDevice]);

  const getPublishRequest = useCallback(() => {
    if (!accountId) {
      return;
    }
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: hubId,
        from: accountId,
        msg: {
          action: "get",
          resource: resourceList[0],
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    setIsFetchScannedDevices(true);
    setRemainingTime(expireTime);
    mqttPublish(context);
  }, []);

  useEffect(() => {
    if (showLoader) {
      callTimeoutLoader();
    }
    return () => {
      clearTimeout(hideLoaderRef.current);
    };
  }, [showLoader]);

  useEffect(() => {
    if (actualDevices?.length && nvrScannedDevicesList?.length) {
      setShowLoader(false);
      setIsFetchScannedDevices(false);
    }
  }, [actualDevices?.length]);

  useEffect(() => {
    return async () => {
      await setNVRScannedDevices([]);
      await setAllScannedDevices([]);
      dispatch(resetAllMqttDataFromResponse());
    };
  }, []);

  const callTimeoutLoader = () => {
    clearTimeout(hideLoaderRef.current);
    hideLoaderRef.current = setTimeout(() => {
      setShowLoader(false);
    }, 60000);
  };

  useEffect(() => {
    const mqttResponseHandler = async () => {
      const resource = allMqttData?.msg?.resource;
      setErrorMsg("");
      if (resource?.includes("hub/channels/auth-creds")) {
        const properties = { ...allMqttData?.msg?.properties };
        // TODO: To Be Removed
        console.log("Authenticate Device Status", properties);
        if (!properties?.device?.authStatus) {
          setErrorMsg(constants.AUTHENTICATE_INVALID);
        }
        setShowLoaderForNotAuth(false);
        const channelDevices = properties?.device?.channels;
        const allUpdatedChannels = [];
        if (channelDevices && channelDevices.length > 0) {
          const updatedDevices = nvrScannedDevicesList?.map((item) => {
            const foundChannel = channelDevices.find(
              (chd) => chd.channel === item.channel
            );
            if (foundChannel) {
              const updatedChannel = {
                ...item,
                authStatus:
                  foundChannel?.authStatus?.toString()?.toLowerCase() === "true"
                    ? true
                    : false,
                serialNumber: foundChannel?.serialNumber
                  ? foundChannel?.serialNumber
                  : item?.serialNumber,
                macAddress: foundChannel?.macAddress
                  ? foundChannel?.macAddress
                  : item?.macAddress,
                manufacturer: foundChannel?.manufacturer
                  ? foundChannel?.manufacturer
                  : item?.manufacturer,
              };
              allUpdatedChannels.push(updatedChannel);
              return updatedChannel;
            } else {
              return item;
            }
          });
          await setNVRScannedDevices(updatedDevices);
          setShowLoader(false);
        }
        const foundChannel = channelDevices?.find(
          (chd) => chd.channel === selectedDevice?.channel
        );
        if (foundChannel) {
          setIsReloadSelectedDevice(!isReloadSelectedDevice);
        }
        const respDevice = properties?.device;
        if (respDevice) {
          const updatedAllScannedDevices = allScannedDevicesList?.map((dvc) => {
            if (dvc.channel === respDevice.channel) {
              return {
                ...dvc,
                serialNumber: respDevice.serialNumber,
                macAddress: respDevice.macAddress,
                manufacturer: respDevice.manufacturer,
                channels: allUpdatedChannels,
              };
            } else {
              return dvc;
            }
          });
          await setAllScannedDevices(updatedAllScannedDevices);
        }
      } else if (resource?.includes("hub/channels")) {
        const properties = { ...allMqttData?.msg?.properties };
        // TODO: To Be Removed
        console.log("Scanned Channel List", properties?.devices);
        if (properties?.devices?.length > 0) {
          // const allDevices = properties?.devices?.map((d) => d.device);
          const allPropsDevices = properties?.devices;
          const allDevices = allPropsDevices?.map((d) => d.device);
          await setAllScannedDevices(allDevices);
          if (nvrDeviceData?.deviceType.toUpperCase() === DeviceTypeEnum.NVR) {
            const list = getChannelsList(allPropsDevices);
            await setNVRScannedDevices(list);
          } else {
            const list = allPropsDevices?.map((d) => d.device);
            await setNVRScannedDevices(list);
          }
        } else {
          await setNVRScannedDevices([]);
          await setAllScannedDevices([]);
          setShowLoader(false);
          setIsFetchScannedDevices(false);
        }
        if (properties) {
          setHasDevices(properties?.devices?.length > 0 ? true : false);
          setRemainingTime(0);
        }
      } else if (resource?.includes("/camera/system/device-status")) {
        const properties = { ...allMqttData?.msg?.properties };
        // TODO: To Be Removed
        console.log("Connection Status", properties);
        if (!properties.hasOwnProperty("online")) {
          return;
        }
        const deviceAttributes = resource.split("/");
        if (allScannedDevicesList?.length) {
          const updatedDevices = allScannedDevicesList?.map((item) => {
            if (item.deviceId === deviceAttributes[1]) {
              return {
                ...item,
                connectionStatus: properties.online
                  ? properties.online
                  : item.connectionStatus,
              };
            } else {
              return item;
            }
          });
          await setDevicesListOfCurrOrg(updatedDevices);
          setDevicesListOfCurrOrgList(updatedDevices);
        }
      } else if (resource?.includes("device/")) {
        const properties = { ...allMqttData?.msg?.properties };
        if (!properties.deviceStatus && !properties.connectionStatus) {
          return;
        }
        const deviceAttributes = resource.split("/");
        let updatedDevices;
        Utils.vmsLogger().log(
          "All Devices before update - ",
          devicesListOfCurrOrgList
        );
        if (devicesListOfCurrOrgList?.length && properties.deviceStatus) {
          Utils.vmsLogger().log(
            "deviceStatus - ",
            deviceAttributes[1],
            properties.deviceStatus
          );
          updatedDevices = devicesListOfCurrOrgList.map((item) => {
            if (item.deviceId === deviceAttributes[1]) {
              return {
                ...item,
                deviceStatus: properties.deviceStatus
                  ? properties.deviceStatus
                  : item.deviceStatus,
              };
            } else {
              return item;
            }
          });
          // if (selectedDevice?.deviceId === deviceAttributes[1]) {
          //   setIsReloadSelectedDevice(!isReloadSelectedDevice);
          // }
          // await setDevicesListOfCurrOrg(updatedDevices);
          setDevicesListOfCurrOrgList(updatedDevices);
          Utils.vmsLogger().log(
            "All Devices after deviceStatus update - ",
            updatedDevices
          );
        }
        if (devicesListOfCurrOrgList?.length && properties.connectionStatus) {
          Utils.vmsLogger().log(
            "connectionStatus - ",
            deviceAttributes[1],
            properties.connectionStatus
          );
          updatedDevices = devicesListOfCurrOrgList.map((item) => {
            if (item.deviceId === deviceAttributes[1]) {
              return {
                ...item,
                connectionStatus: properties.connectionStatus
                  ? properties.connectionStatus
                  : item.connectionStatus,
              };
            } else {
              return item;
            }
          });
          setDevicesListOfCurrOrgList(updatedDevices);
          Utils.vmsLogger().log(
            "All Devices after connectionStatus update - ",
            updatedDevices
          );
        }
      }
    };

    mqttResponseHandler();
  }, [allMqttData]);

  const getDeviceProperties = (passedItem) => {
    let device = devicesListOfCurrOrgList?.find(
      (item) =>
        item?.macAddress &&
        item.deviceStatus !== constants.DEVICES_DEACTIVATED_DEVICE_STATUS &&
        passedItem.macAddress &&
        item?.macAddress?.toUpperCase() ===
          passedItem?.macAddress?.toUpperCase() &&
        item.serialNo === passedItem.serialNumber
    );
    if (!device) {
      device = devicesListOfCurrOrgList?.find(
        (item) =>
          item?.macAddress &&
          passedItem.macAddress &&
          item?.macAddress?.toUpperCase() ===
            passedItem?.macAddress?.toUpperCase() &&
          item.serialNo === passedItem.serialNumber
      );
    }
    return {
      ...device,
      channel: passedItem.channel,
      deviceType: passedItem.deviceType,
      deviceAuthStatus: getDeviceStatus({ ...passedItem, ...device }),
    };
  };

  const getDeviceStatus = (device) => {
    if (!device.authStatus) {
      return DeviceStatusEnum.NOT_AUTHENTICATED;
    } else {
      if (device.gatewayId === hubId) {
        if (
          device.connectionStatus ===
          constants.DEVICES_OFFLINE_CONNECTION_STATUS
        ) {
          return DeviceStatusEnum.CON_OFFLINE;
        } else if (
          device.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS
        ) {
          return DeviceStatusEnum.ADDED_N_CLAIMED;
        } else if (
          device.deviceStatus === constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS
        ) {
          return DeviceStatusEnum.ADDED_NOT_CLAIMED;
          // } else if (
          //   device.deviceStatus === constants.DEVICES_DEACTIVATED_DEVICE_STATUS
          // ) {
          //   return DeviceStatusEnum.DEACTIVATED;
        } else {
          return DeviceStatusEnum.AUTHENTICATED;
        }
      }
      if (device.deviceStatus === constants.DEVICES_DEACTIVATED_DEVICE_STATUS) {
        return DeviceStatusEnum.AUTHENTICATED;
      }
      if (!device.deviceId) {
        return DeviceStatusEnum.AUTHENTICATED;
      } else {
        return DeviceStatusEnum.ALREADY_ASSIGNED;
      }
    }
  };

  const handleCompleteClick = () => {
    hideModalHandler();
  };

  const handleDeviceClick = (deviceId) => {
    if (loaderForSelectedDevice) return;
    const activeDevice = scannedDevices.find(
      (device) => device.channel === deviceId
    );
    setSelectedDevice(activeDevice);
    setIsFetchScannedDevices(false);
  };

  const handleDeviceClickByDeviceId = (deviceId) => {
    if (loaderForSelectedDevice) return;
    const activeDevice = scannedDevices.find(
      (device) => device.deviceId === deviceId
    );
    if (activeDevice) setSelectedDevice(activeDevice);
    setIsReloadSelectedDevice(!isReloadSelectedDevice);
  };

  const handleScanNetworkClick = async () => {
    if (
      nvrDeviceData?.deviceType.toUpperCase() !== DeviceTypeEnum.DMSERVER &&
      addedDeviceCount.current >= maxDeviceCount
    ) {
      setShowMaxLimitMsg(true);
      return;
    }
    if (loaderForSelectedDevice) return;
    setShowLoader(true);
    addedDeviceCount.current = 0;
    setHasDevices(null);
    setActualDevices([]);
    await setNVRScannedDevices([]);
    await setAllScannedDevices([]);
    isReInitiateScanNetworkReq.current = !isReInitiateScanNetworkReq.current;
  };

  const getChannelsList = (data) => {
    const list = [];
    data.forEach((d) => {
      d.device && d.device.channels && list.push(...d.device.channels);
    });
    return list;
  };

  const DiscoveredDevicesModalFooter = useMemo(
    (footerExtraClass) =>
      ({ footerExtraClass }) =>
        (
          <div className={`footer-container ${footerExtraClass}`}>
            <div className="left-section">
              <img
                src={nvrDeviceData?.imageURL}
                alt="Device"
                className="device-icon"
              />
              <div className="device-details">
                <div className="device-name">{nvrDeviceData?.deviceName}</div>
                <div className="device-added-status">
                  {addedDeviceCount.current} /{" "}
                  {nvrDeviceData?.deviceType?.toUpperCase() !==
                  DeviceTypeEnum.DMSERVER ? (
                    maxDeviceCount
                  ) : (
                    <span>
                      <BsInfinity size={16} />
                    </span>
                  )}
                  {constants.DEVICES_ADDED_TEXT}
                </div>
              </div>
            </div>
            <div className="right-section">
              <div
                className={`scan-network-button ${
                  nvrDeviceData?.deviceType.toUpperCase() !==
                    DeviceTypeEnum.DMSERVER &&
                  addedDeviceCount.current >= maxDeviceCount
                    ? "disabled"
                    : ""
                }`}
              >
                <RefreshIcon
                  className={`refresh-icon ${
                    showLoader ||
                    isFetchScannedDevices ||
                    (nvrDeviceData?.deviceType.toUpperCase() !==
                      DeviceTypeEnum.DMSERVER &&
                      addedDeviceCount.current >= maxDeviceCount)
                      ? "disabled-icon"
                      : ""
                  }`}
                />
                <PrimaryButton
                  className="scan-button"
                  type="button"
                  width="fit-content"
                  borderWidth="0"
                  hoverBorderWidth="0"
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--primary_40")}
                  hoverColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--primary_40")}
                  disabledBackgroundColor="transparent"
                  disabledColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--greyscale_72")}
                  hoverBackgroundColor="transparent"
                  backgroundColor="transparent"
                  disabled={
                    showLoader ||
                    isFetchScannedDevices ||
                    (nvrDeviceData?.deviceType.toUpperCase() !==
                      DeviceTypeEnum.DMSERVER &&
                      addedDeviceCount.current >= maxDeviceCount)
                  }
                  onClick={handleScanNetworkClick}
                >
                  {constants.DISCOVERED_DEVICES_SCAN_BUTTON_TEXT}
                </PrimaryButton>
              </div>
              <PrimaryButton
                fontSize="0.875rem"
                backgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--brand_white")}
                height="44px"
                width="169px"
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--primary_40")}
                type="submit"
                onClick={handleCompleteClick}
              >
                {constants.COMPLETE_TEXT}
              </PrimaryButton>
            </div>
          </div>
        ),
    [showLoader, isFetchScannedDevices]
  );

  const handleTabChange = (tab) => {
    if (loaderForSelectedDevice) return;
    setSelectedTabKey(tab);
    switch (tab) {
      case "Added":
        setSelectedTab([
          DeviceStatusEnum.CON_OFFLINE,
          DeviceStatusEnum.ADDED_N_CLAIMED,
          DeviceStatusEnum.ADDED_NOT_CLAIMED,
        ]);
        break;
      case "Unauthenticated":
        setSelectedTab([DeviceStatusEnum.NOT_AUTHENTICATED]);
        break;
      case "Already Assigned":
        setSelectedTab([DeviceStatusEnum.ALREADY_ASSIGNED]);
        break;

      default:
        setSelectedTab([]);
        setSelectedDevice(undefined);
        break;
    }
  };

  const getChannelStatus = (status) => {
    switch (status) {
      case DeviceStatusEnum.AUTHENTICATED:
        return (
          <div className="status-section">
            <>
              <img src={TickOutlined} alt="TickOutlined" />
              {constants.AUTHENTICATED_STATUS}
            </>
          </div>
        );
      case DeviceStatusEnum.CON_OFFLINE:
        return (
          <div className="error-status-section">
            <>
              <ErrorIcon />
              {constants.DEVICES_OFFLINE_CONNECTION_STATUS}
            </>
          </div>
        );
      case DeviceStatusEnum.ADDED_N_CLAIMED:
        return (
          <div className="status-section">
            <>
              <img src={TickOutlined} alt="TickOutlined" />
              {constants.ADDED_CLAIMED_STATUS}
            </>
          </div>
        );
      case DeviceStatusEnum.ADDED_NOT_CLAIMED:
        return (
          <div className="pending-status-section">
            <>
              <ExclaimationIcon />
              {constants.PENDING_CLAIMED_STATUS}
            </>
          </div>
        );

      default:
        break;
    }
  };

  const fetchDeviceListForOrg = async () => {
    try {
      // setShowLoader(true);
      axios
        .get(`device/orgs/${orgInfo?.orgId}/devices`, Utils.requestHeader())
        .then(async (response) => {
          const resultData = response.data;
          if (resultData) {
            const responseMeta = resultData.meta;
            const responseData = resultData.data;
            if (
              responseMeta &&
              (responseMeta.code === 200 || responseMeta.code === "200")
            ) {
              await setDevicesListOfCurrOrg(responseData);
              setDevicesListOfCurrOrgList(responseData);
              // setIsReloadSelectedDevice(!isReloadSelectedDevice);
            } else if (response?.response?.data?.error) {
              setErrorMsg(response?.response?.data?.error);
              setShowLoader(false);
            }
          } else {
            setShowLoader(false);
          }
          setTimeout(() => {
            dispatch(setAddedAndClaimedDeviceId(null));
          }, 1000);
        });
    } catch (error) {
      setShowLoader(false);
      console.error("ERROR: ", error);
    }
  };

  const handleErrorMsgClose = () => {
    setErrorMsg("");
  };

  const handleInfoMsgClose = () => {
    setShowMaxLimitMsg(false);
  };

  return (
    <div className={`device-container ${errorMsg && "errorMsg"}`}>
      {/* for Mobile */}
      <DiscoveredDevicesModalFooter footerExtraClass="mobileView" />
      {showLoader ||
      isFetchScannedDevices ||
      (hasDevices && !nvrScannedDevicesList?.length) ? (
        <div className="loading-screen">
          <Loader />
          <div className="mt-4">{constants.DISCOVERED_ONVIF_DEVICES_TEXT}</div>
        </div>
      ) : hasDevices === false ? (
        <div className="loading-screen">
          <NoDeviceIcon />
          <div className="mt-4">{constants.DEVICES_NOT_FOUND_TEXT}</div>
        </div>
      ) : (
        <>
          {errorMsg ? (
            <div className="mb-4 warning-container">
              <div className="warning-message">
                <InfoCircleIcon />
                {errorMsg}
              </div>
              <RxCross1
                className="site-modal-close"
                onClick={handleErrorMsgClose}
              />
            </div>
          ) : nvrDeviceData &&
            nvrDeviceData?.deviceType.toUpperCase() !==
              DeviceTypeEnum.DMSERVER &&
            showMaxLimitMsg &&
            addedDeviceCount.current >= maxDeviceCount ? (
            <div className="mb-4 warning-container">
              <div className="warning-message">
                <InfoCircleIcon />
                {constants.DEVICES_REACHED_MAX_LIMIT.replace(
                  "${count}",
                  maxDeviceCount
                )}
              </div>
              <RxCross1
                className="site-modal-close"
                onClick={handleInfoMsgClose}
              />
            </div>
          ) : (
            ""
          )}
          <div className="pb-4 filter-container">
            <Tabs
              defaultActiveKey={filterTabs[0]}
              className="filter-tabs"
              activeKey={selectedTabKey}
              onSelect={(e) => {
                handleTabChange(e);
              }}
            >
              {filterTabs.map((tab) => (
                <Tab key={tab} eventKey={tab} title={tab} />
              ))}
            </Tabs>
          </div>
          {scannedDevices?.length > 0 ? (
            <div className="devices-section mb-4">
              <div className="devices-list">
                {scannedDevices.map((device, index) => (
                  <div
                    key={device.channel + index}
                    className={`device-item ${
                      device.channel === selectedDevice?.channel
                        ? "active-device"
                        : ""
                    }`}
                    onClick={() => handleDeviceClick(device.channel)}
                  >
                    <div className="item-details">
                      <div className="item-name">{device.modelNumber}</div>
                      <div className="mt-2 seconday-text">
                        {constants.IP_LABEL} {device.ipAddress}
                      </div>
                      <div className="seconday-text">
                        {constants.PORT_LABEL} {device.port}
                      </div>
                      {device.serialNumber && (
                        <div className="seconday-text">
                          {constants.SERIAL_NUMBER_LABEL} {device.serialNumber}
                        </div>
                      )}
                    </div>
                    <div
                      className={`item-status ${
                        device.deviceAuthStatus ===
                        DeviceStatusEnum.ALREADY_ASSIGNED
                          ? "already-assigned-status"
                          : ""
                      } ${
                        (device.deviceAuthStatus ===
                          DeviceStatusEnum.AUTHENTICATED ||
                          device.deviceAuthStatus ===
                            DeviceStatusEnum.NOT_AUTHENTICATED) &&
                        nvrDeviceData?.deviceType.toUpperCase() !==
                          DeviceTypeEnum.DMSERVER &&
                        addedDeviceCount.current >= maxDeviceCount
                          ? "disabled"
                          : ""
                      }`}
                    >
                      {device.deviceAuthStatus !==
                        DeviceStatusEnum.CON_OFFLINE &&
                        device.deviceAuthStatus !==
                          DeviceStatusEnum.ADDED_N_CLAIMED &&
                        device.deviceAuthStatus !==
                          DeviceStatusEnum.ADDED_NOT_CLAIMED &&
                        device.deviceAuthStatus}
                    </div>
                  </div>
                ))}
              </div>
              <div className="device-details">
                {loaderForSelectedDevice && <Loader />}
                <div
                  className={`device-details-container ${
                    (selectedDevice?.deviceAuthStatus ===
                      DeviceStatusEnum.NOT_AUTHENTICATED ||
                      selectedDevice?.deviceAuthStatus ===
                        DeviceStatusEnum.AUTHENTICATED) &&
                    nvrDeviceData?.deviceType.toUpperCase() !==
                      DeviceTypeEnum.DMSERVER &&
                    addedDeviceCount.current >= maxDeviceCount
                      ? "disable-form"
                      : ""
                  }`}
                >
                  <div className="header-container">
                    <div className="title-section">
                      <div className="item-name">
                        {selectedDevice?.modelNumber}
                      </div>
                    </div>
                    {getChannelStatus(selectedDevice?.deviceAuthStatus)}
                  </div>
                  <div>
                    {selectedDevice?.deviceAuthStatus ===
                    DeviceStatusEnum.ALREADY_ASSIGNED ? (
                      <MoveDeviceControl
                        errorMsgHandler={setErrorMsg}
                        selectedDevice={selectedDevice}
                        nvrDeviceData={nvrDeviceData}
                        devicesListOfCurrOrgList={devicesListOfCurrOrgList}
                        hubId={hubId}
                        deviceSelectHandler={handleDeviceClickByDeviceId}
                        selectedDeviceLoaderHandler={setLoaderForSelectedDevice}
                        fetchDeviceListForOrg={fetchDeviceListForOrg}
                        updateDeviceHandler={setDevicesListOfCurrOrgList}
                        loaderForSelectedDevice={loaderForSelectedDevice}
                      />
                    ) : selectedDevice?.deviceAuthStatus ===
                      DeviceStatusEnum.DEACTIVATED ? (
                      <ReClaimDeviceControl
                        errorMsgHandler={setErrorMsg}
                        selectedDevice={selectedDevice}
                        devicesListOfCurrOrgList={devicesListOfCurrOrgList}
                        deviceSelectHandler={handleDeviceClickByDeviceId}
                        selectedDeviceLoaderHandler={setLoaderForSelectedDevice}
                      />
                    ) : selectedDevice?.deviceAuthStatus ===
                        DeviceStatusEnum.AUTHENTICATED ||
                      selectedDevice?.deviceAuthStatus ===
                        DeviceStatusEnum.CON_OFFLINE ||
                      selectedDevice?.deviceAuthStatus ===
                        DeviceStatusEnum.ADDED_N_CLAIMED ||
                      selectedDevice?.deviceAuthStatus ===
                        DeviceStatusEnum.ADDED_NOT_CLAIMED ? (
                      <AddAndUpdateDeviceControl
                        errorMsgHandler={setErrorMsg}
                        selectedDevice={selectedDevice}
                        nvrDeviceData={nvrDeviceData}
                        devicesListOfCurrOrgList={devicesListOfCurrOrgList}
                        hubId={hubId}
                        isAddButtonDisabled={
                          selectedDevice?.deviceAuthStatus ===
                            DeviceStatusEnum.AUTHENTICATED &&
                          nvrDeviceData?.deviceType.toUpperCase() !==
                            DeviceTypeEnum.DMSERVER &&
                          addedDeviceCount.current >= maxDeviceCount
                        }
                        deviceSelectHandler={handleDeviceClickByDeviceId}
                        selectedDeviceLoaderHandler={setLoaderForSelectedDevice}
                        fetchDeviceListForOrg={fetchDeviceListForOrg}
                        scanDeviceRequestHandler={getPublishRequest}
                        updateDeviceHandler={setDevicesListOfCurrOrgList}
                        loaderForSelectedDevice={loaderForSelectedDevice}
                      />
                    ) : (
                      <NotAuthenticatedControl
                        selectedDevice={selectedDevice}
                        scannedDevices={scannedDevices}
                        hubId={hubId}
                        selectedDeviceLoaderHandler={setShowLoaderForNotAuth}
                        loader={showLoaderForNotAuth}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="no-device-found loading-screen">
              <NoDeviceIcon />
              <div className="mt-4">{constants.DEVICES_NOT_FOUND_TEXT}</div>
            </div>
          )}
        </>
      )}
      <DiscoveredDevicesModalFooter footerExtraClass="desktopView" />
    </div>
  );
};
export default DiscoveredDevices;
