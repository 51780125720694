import { useEffect, useRef, useState, useCallback } from "react";
import { Formik, Form } from "formik";
import { useSelector } from "react-redux";
import { TextField } from "../../../../components/forms";
import {
  DeviceStatusEnum,
  DeviceTypeEnum,
  constants,
} from "../../../../helpers";
import { PrimaryButton } from "../../../../components/common";
import { ReactComponent as InfoCircleIcon } from "../../../../assets/images/Info-circle.svg";
import DummyImageIcon from "../../../../assets/images/dummy-snapshot.svg";
import { moveDeviceToHubAPI } from "../addDeviceAPI";
import { observerInstance } from "../../../../store/indexDB/observer";
import useDebouncedCallback from "../../../../hooks/useDebouncedCallback";
import { getAllScannedDevices } from "../../../../store/NVRDeviceStoreIDB";
import { getOrgInfo } from "../../../../store/reducers/OrganizationsReducer";

const MoveDeviceControl = ({
  selectedDevice,
  nvrDeviceData,
  hubId,
  deviceSelectHandler,
  selectedDeviceLoaderHandler,
  errorMsgHandler,
  isMobilePopup,
  hideModal,
  fetchDeviceListForOrg,
  devicesListOfCurrOrgList,
  updateDeviceHandler,
  loaderForSelectedDevice,
}) => {
  const formikRef = useRef();
  const [allScannedDevicesList, setAllScannedDevicesList] = useState();
  const orgInfo = useSelector(getOrgInfo);

  const loadAllScannedDevices = useCallback(async () => {
    const allDevices = await getAllScannedDevices();
    setAllScannedDevicesList(allDevices || []);
  }, []);

  const debouncedLoadAllScannedDevices = useDebouncedCallback(
    loadAllScannedDevices,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === "allScannedDevices") {
        await debouncedLoadAllScannedDevices();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadAllScannedDevices();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadAllScannedDevices]);

  useEffect(() => {
    formikRef?.current?.resetForm();
  }, [selectedDevice?.channel]);

  const getDeviceProperties = (passedItem) => {
    const devices = devicesListOfCurrOrgList?.filter(
      (item) =>
        item.macAddress &&
        passedItem.macAddress &&
        item.macAddress.toUpperCase() === passedItem.macAddress.toUpperCase() &&
        item.serialNo === passedItem.serialNumber
    );
    const device = getDeviceDetails(devices);
    return device;
  };

  const getDeviceDetails = (devices) => {
    if (devices?.length > 0) {
      const device = devices.find(
        (x) => x.deviceStatus === constants.DEVICE_STATUS_CLAIMED
      );
      return device ? device : devices?.[0];
    }
  };

  const handleMoveDeviceToHub = async (values) => {
    try {
      if (loaderForSelectedDevice) {
        return;
      }
      errorMsgHandler("");
      selectedDeviceLoaderHandler(true);
      let payload = [];
      if (nvrDeviceData?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR) {
        const parentDevice = allScannedDevicesList?.find(
          (a) =>
            a.macAddress &&
            selectedDevice.macAddress &&
            a.macAddress.toUpperCase() ===
              selectedDevice.macAddress.toUpperCase()
        );
        const parentWithDeviceDetails = getDeviceProperties(parentDevice);
        payload = {
          deviceId: parentWithDeviceDetails?.deviceId,
          locationId: nvrDeviceData?.locationId,
          deviceName:
            parentWithDeviceDetails?.deviceName ||
            parentWithDeviceDetails?.serialNumber,
          areaId: nvrDeviceData?.areaId,
          channel: parentDevice.channel,
          deviceType: parentDevice.deviceType,
          channels: parentDevice?.channels?.map((ch) => {
            const channelWithDeviceDetails = getDeviceProperties(ch);
            return {
              deviceId: channelWithDeviceDetails?.deviceId,
              locationId: nvrDeviceData?.locationId,
              deviceName:
                selectedDevice.channel === ch.channel
                  ? values.deviceName || ch.serialNumber
                  : ch.serialNumber,
              areaId: nvrDeviceData?.areaId,
              channel: ch.channel,
              deviceType: ch.deviceType,
            };
          }),
        };
      } else {
        payload = {
          deviceId: selectedDevice?.deviceId,
          locationId: nvrDeviceData?.locationId,
          deviceName: values?.deviceName || selectedDevice?.serialNumber,
          areaId: nvrDeviceData?.areaId,
          channel: selectedDevice.channel,
          deviceType: selectedDevice.deviceType,
          channels: selectedDevice?.channels?.map((ch) => {
            const channelWithDeviceDetails = getDeviceProperties(ch);
            return {
              deviceId: channelWithDeviceDetails?.deviceId,
              locationId: nvrDeviceData?.locationId,
              deviceName:
                channelWithDeviceDetails?.deviceName || ch.serialNumber,
              areaId: nvrDeviceData?.areaId,
              channel: ch.channel,
              deviceType: ch.deviceType,
            };
          }),
        };
      }
      const deviceResponse = await moveDeviceToHubAPI(orgInfo, payload, hubId);
      if (deviceResponse?.meta?.code === 200) {
        const data = deviceResponse?.data;
        const device = data?.device;
        const channels = data?.channels;
        const devicesToUpdate = [device, ...channels];
        if (device) {
          const newEntries = devicesToUpdate
            .map((d) => {
              const foundDevice = devicesListOfCurrOrgList?.find(
                (x) =>
                  x.macAddress === d.macAddress && x.serialNo === d.serialNo
              );
              return foundDevice ? undefined : d;
            })
            ?.filter((x) => x);
          const updatedDevices = [
            ...devicesListOfCurrOrgList,
            ...newEntries,
          ].map((d) => {
            const foundDevice = devicesToUpdate.find(
              (x) => x.macAddress === d.macAddress && x.serialNo === d.serialNo
            );
            return foundDevice ? foundDevice : d;
          });
          // dispatch(setDevicesListOfCurrOrg(updatedDevices));
          updateDeviceHandler(updatedDevices);
          deviceSelectHandler(selectedDevice.deviceId);
          fetchDeviceListForOrg();
        }
      } else {
        errorMsgHandler(deviceResponse?.userMsg);
      }
      selectedDeviceLoaderHandler(false);
      if (isMobilePopup) {
        hideModal();
      }
    } catch (error) {
      selectedDeviceLoaderHandler(false);
      console.error("ERROR: ", error);
    }
  };

  const getHubName = (gatewayId) => {
    const device = devicesListOfCurrOrgList?.find(
      (item) => item.deviceId === gatewayId
    );
    return device?.deviceName;
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        deviceName: selectedDevice?.deviceName,
      }}
      onSubmit={async (values) => {
        handleMoveDeviceToHub(values);
      }}
      innerRef={formikRef}
    >
      {({ isValid, handleSubmit, handleChange }) => (
        <Form className="modal-form" onSubmit={handleSubmit}>
          {selectedDevice?.deviceAuthStatus ===
            DeviceStatusEnum.ALREADY_ASSIGNED && (
            <div className="warning-container">
              <div className="warning-message">
                <InfoCircleIcon />
                {constants.DEVICES_ALREADY_ASSIGNED_TEXT.replace(
                  "${deviceId}",
                  getHubName(selectedDevice.gatewayId)
                )}
              </div>
            </div>
          )}
          <div className="device-image-wrapper">
            <img src={DummyImageIcon} alt="DummyImageIcon" />
          </div>
          <div className="fieldTitle mb-1 mt-3">
            {constants.DEVICES_DEVICE_NAME_TEXT}
          </div>
          <div className="">
            <TextField
              placeholder={constants.DEVICES_DEVICE_NAME_TEXT}
              name="deviceName"
              type="text"
              removebottommargin="true"
              removetopmargin="true"
              disabled={true}
              onChange={handleChange}
            />
          </div>
          <PrimaryButton
            className="mt-4"
            fontSize="14px"
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue("--primary_40")}
            height="44px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              "--brand_white"
            )}
            disabled={!isValid}
          >
            {constants.MOVE_DEVICE_TO_BUTTON_TEXT} {nvrDeviceData?.deviceName}
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  );
};

export default MoveDeviceControl;
