import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Loader, PrimaryButton } from "../../../components/common";
import { RxCross1 } from "react-icons/rx";
import {
  Utils,
  constants,
  DeviceStatusEnum,
  DeviceTypeEnum,
} from "../../../helpers";
import { Row, Col } from "react-bootstrap";
import { mqttPublish } from "../../../utils/connection/mqttConnection";
import { useLoggedInUserData } from "../../../store/LoggedInAccountStore";
import { devicesMQTTStore } from "../../../store/DevicesMQTTStore";
import { ReactComponent as RefreshIcon } from "../../../assets/images/refresh.svg";
import { ReactComponent as InfoCircleIcon } from "../../../assets/images/Info-circle.svg";
import { ReactComponent as NoDeviceIcon } from "../../../assets/images/no-device.svg";
import { ReactComponent as ExclaimationIcon } from "../../../assets/images/expired.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/images/icons/errorIcon.svg";
import { TickOutlined } from "../../../assets/images";
import MoveDeviceControl from "./NVR/MoveDeviceControl";
import AddAndUpdateDeviceControl from "./NVR/AddAndUpdateDeviceControl";
import NotAuthenticatedControl from "./NVR/NotAuthenticatedControl";
import ReClaimDeviceControl from "./NVR/ReClaimDeviceControl";
import { getNVRDeviceData } from "../../../store/reducers/NVRDeviceReducer";
import { getAllMqttDataFromResponse } from "../../../store/reducers/AccountReducer";
import { SiteModal } from "../../../components/common/";
import { getSelectedOrganization } from "../../../store/AccountStoreIDB";
import {
  getAllScannedDevices,
  getDevicesListOfCurrOrg,
  getNVRScannedDevices,
  setAllScannedDevices,
  setDevicesListOfCurrOrg,
  setNVRScannedDevices,
} from "../../../store/NVRDeviceStoreIDB";
import { observerInstance } from "../../../store/indexDB/observer";
import useDebouncedCallback from "../../../hooks/useDebouncedCallback";

const DiscoveredDevicesMobile = ({ hideModalHandler }) => {
  const expireTime = 60;
  const resourceList = Utils.getDeviceSettingResource(11);
  const nvrDeviceData = useSelector(getNVRDeviceData);
  const [orgDetails, setOrgDetails] = useState();
  const [devicesListOfCurrOrgList, setDevicesListOfCurrOrgList] = useState([]);
  const maxDeviceCount = nvrDeviceData?.channelCount || 0;
  const [nvrScannedDevicesList, setNVRScannedDevicesList] = useState([]);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const { getState } = devicesMQTTStore;
  const state = getState();
  const sessionId = state.getSessionId();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const tid = Math.floor(new Date().getTime() / 1000.0);
  const accountId = loggedInUserData.accountId;
  const hubId = nvrDeviceData?.gatewayId;
  const appTopic = `a/notify/${hubId}`;
  const [showLoader, setShowLoader] = useState(false);
  const [loaderForSelectedDevice, setLoaderForSelectedDevice] = useState(false);
  const [scannedDevices, setScannedDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const addedDeviceCount = useRef(0);
  const isReInitiateScanNetworkReq = useRef(true);
  const [selectedTab, setSelectedTab] = useState([]);
  const [actualDevices, setActualDevices] = useState([]);
  const [isReloadSelectedDevice, setIsReloadSelectedDevice] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isFetchScannedDevices, setIsFetchScannedDevices] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [showDiscoveredDeviceMobileModal, setShowDiscoveredDeviceMobileModal] =
    useState(false);
  const [allScannedDevicesList, setAllScannedDevicesList] = useState();
  const [showMaxLimitMsg, setShowMaxLimitMsg] = useState(true);

  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  const loadAllScannedDevices = useCallback(async () => {
    const allDevices = await getAllScannedDevices();
    setAllScannedDevicesList(allDevices || []);
  }, []);

  const loadNVRScannedDevices = useCallback(async () => {
    const allDevices = await getNVRScannedDevices();
    setNVRScannedDevicesList(allDevices || []);
  }, []);

  const loadDevicesListOfCurrOrgList = useCallback(async () => {
    const allDevices = await getDevicesListOfCurrOrg();
    setDevicesListOfCurrOrgList(allDevices || []);
  }, []);

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000
  );
  const debouncedLoadAllScannedDevices = useDebouncedCallback(
    loadAllScannedDevices,
    1000
  );
  const debouncedLoadNVRScannedDevices = useDebouncedCallback(
    loadNVRScannedDevices,
    1000
  );
  const debouncedLoadDevicesListOfCurrOrgList = useDebouncedCallback(
    loadDevicesListOfCurrOrgList,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === "selectedOrganization") {
        await debouncedLoadSelectedOrgData();
      }
      if (data.key === "allScannedDevices") {
        await debouncedLoadAllScannedDevices();
      }
      if (data.key === "nvrScannedDevices") {
        await debouncedLoadNVRScannedDevices();
      }
      if (data.key === "devicesListOfCurrOrg") {
        await debouncedLoadDevicesListOfCurrOrgList();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSelectedOrgData();
    debouncedLoadAllScannedDevices();
    debouncedLoadNVRScannedDevices();
    debouncedLoadDevicesListOfCurrOrgList();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [
    debouncedLoadSelectedOrgData,
    debouncedLoadAllScannedDevices,
    debouncedLoadNVRScannedDevices,
    debouncedLoadDevicesListOfCurrOrgList,
  ]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1);
      } else {
        setIsFetchScannedDevices(false);
        clearInterval(timerInterval);
      }
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, [remainingTime]);

  const findMissingDevices = (devicesList) => {
    const missingDevices = [];
    devicesList.forEach((item) => {
      const existsInList = nvrScannedDevicesList.some(
        (childItem) =>
          childItem.macAddress &&
          item.macAddress &&
          childItem.macAddress.toUpperCase() ===
            item.macAddress.toUpperCase() &&
          (childItem.serialNo === item.serialNo ||
            childItem.serialNumber === item.serialNo)
      );
      if (!existsInList) {
        missingDevices.push({
          ...item,
          serialNumber: item.serialNo, // Key is different in scan network device mqtt response.
          ipAddress: item.properties?.["ip-address"], // IP Address is in properties of a device
          port: "", // This is missing in device
          authStatus: true, // Added channel always authenticated because after authentication it can be added
        });
      }
    });
    return missingDevices;
  };

  useEffect(() => {
    if (nvrScannedDevicesList && nvrScannedDevicesList?.length > 0) {
      const alreadyAddedDevices = nvrDeviceData?.childDevices;
      const missingDevices =
        alreadyAddedDevices?.length > 0
          ? findMissingDevices(alreadyAddedDevices)
          : [];
      const allDevices = [...nvrScannedDevicesList, ...missingDevices];
      const updatedDevices = allDevices?.map((item) => ({
        ...item,
        ...getDeviceProperties(item),
      }));
      const devicesWithStatus = updatedDevices?.filter(
        (item) => item.deviceAuthStatus
      );
      setActualDevices(devicesWithStatus);
      const filteredDevicesByTab =
        selectedTab.length > 0
          ? devicesWithStatus?.filter((item) =>
              selectedTab.includes(item.deviceAuthStatus)
            )
          : devicesWithStatus;
      setScannedDevices(filteredDevicesByTab);
      if (selectedTab.length > 0) {
        setSelectedDevice(filteredDevicesByTab?.[0]);
      } else {
        setSelectedDevice(
          selectedDevice ? selectedDevice : updatedDevices?.[0]
        );
      }
      const deviceCount =
        devicesWithStatus?.filter(
          (item) =>
            item.deviceAuthStatus === DeviceStatusEnum.CON_OFFLINE ||
            item.deviceAuthStatus === DeviceStatusEnum.ADDED_N_CLAIMED ||
            item.deviceAuthStatus === DeviceStatusEnum.ADDED_NOT_CLAIMED
        )?.length || 0;
      addedDeviceCount.current = deviceCount;
    }
  }, [devicesListOfCurrOrgList, nvrScannedDevicesList, selectedTab]);

  useEffect(() => {
    if (orgDetails?.orgId) {
      getPublishRequest();
      fetchDeviceListForOrg();
    }
  }, [isReInitiateScanNetworkReq.current]);

  const getPublishRequest = useCallback(() => {
    if (!accountId) {
      return;
    }
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: hubId,
        from: accountId,
        msg: {
          action: "get",
          resource: resourceList[0],
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    setIsFetchScannedDevices(true);
    setRemainingTime(expireTime);
    mqttPublish(context);
  }, []);

  useEffect(() => {
    const mqttResponseHandler = async () => {
      const resource = allMqttData?.msg?.resource;
      if (resource?.includes("hub/channels/auth-creds")) {
        const properties = { ...allMqttData?.msg?.properties };
        // TODO: To Be Removed
        console.log("Authenticate Device Status", properties);
        const updatedDevices = nvrScannedDevicesList?.map((item) => {
          if (item.channel === properties.channel) {
            return {
              ...item,
              authStatus:
                properties?.["auth-status"]?.toString()?.toLowerCase() ===
                "true"
                  ? true
                  : false,
              serialNo: properties?.serialNo,
            };
          } else {
            return item;
          }
        });
        await setNVRScannedDevices(updatedDevices);
        if (
          selectedDevice?.macAddress &&
          properties?.macAddress &&
          selectedDevice?.macAddress.toUpperCase() ===
            properties?.macAddress.toUpperCase()
        ) {
          setIsReloadSelectedDevice(!isReloadSelectedDevice);
        }
      } else if (resource?.includes("hub/channels")) {
        const properties = { ...allMqttData?.msg?.properties };
        // TODO: To Be Removed
        console.log("Scanned Channel List", properties?.devices);
        if (properties?.devices?.length > 0) {
          // const allDevices = properties?.devices?.map((d) => d.device);
          const allPropsDevices = properties?.devices;
          const allDevices = allPropsDevices?.map((d) => d.device);
          await setAllScannedDevices(allDevices);
          if (nvrDeviceData?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR) {
            const list = getChannelsList(allPropsDevices);
            await setNVRScannedDevices(list);
          } else {
            const list = allPropsDevices?.map((d) => d.device);
            await setNVRScannedDevices(list);
          }
        } else {
          await setNVRScannedDevices([]);
          await setAllScannedDevices([]);
        }
        setIsFetchScannedDevices(false);
        setRemainingTime(0);
      } else if (resource?.includes("/camera/system/device-status")) {
        const properties = { ...allMqttData?.msg?.properties };
        // TODO: To Be Removed
        console.log("Connection Status", properties);
        const deviceAttributes = resource.split("/");
        const updatedDevices = devicesListOfCurrOrgList?.map((item) => {
          if (item.deviceId === deviceAttributes[1]) {
            return {
              ...item,
              connectionStatus: properties.online
                ? properties.online
                : item.connectionStatus,
            };
          } else {
            return item;
          }
        });
        await setDevicesListOfCurrOrg(updatedDevices);
      } else if (resource?.includes("device/")) {
        const properties = { ...allMqttData?.msg?.properties };
        if (!properties.deviceStatus) {
          return;
        }
        const deviceAttributes = resource.split("/");
        const updatedDevices = devicesListOfCurrOrgList?.map((item) => {
          if (item.deviceId === deviceAttributes[1]) {
            return {
              ...item,
              deviceStatus: properties.deviceStatus
                ? properties.deviceStatus
                : item.deviceStatus,
            };
          } else {
            return item;
          }
        });
        await setDevicesListOfCurrOrg(updatedDevices);
      }
    };

    mqttResponseHandler();
  }, [allMqttData]);

  const getDeviceProperties = (passedItem) => {
    const device = devicesListOfCurrOrgList?.find(
      (item) =>
        item.macAddress &&
        passedItem.macAddress &&
        item.macAddress.toUpperCase() === passedItem.macAddress.toUpperCase() &&
        item.serialNumber === passedItem.serialNo
    );
    return {
      ...device,
      channel: passedItem.channel,
      deviceType: passedItem.deviceType,
      deviceAuthStatus: getDeviceStatus({ ...passedItem, ...device }),
    };
  };

  const getDeviceStatus = (device) => {
    if (!device.authStatus) {
      return DeviceStatusEnum.NOT_AUTHENTICATED;
    } else {
      if (device.gatewayId === hubId) {
        if (
          device.connectionStatus ===
          constants.DEVICES_OFFLINE_CONNECTION_STATUS
        ) {
          return DeviceStatusEnum.CON_OFFLINE;
        } else if (
          device.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS
        ) {
          return DeviceStatusEnum.ADDED_N_CLAIMED;
        } else if (
          device.deviceStatus === constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS
        ) {
          return DeviceStatusEnum.ADDED_NOT_CLAIMED;
        } else {
          return DeviceStatusEnum.AUTHENTICATED;
        }
      }
      if (device.deviceStatus === constants.DEVICES_DEACTIVATED_DEVICE_STATUS) {
        return DeviceStatusEnum.AUTHENTICATED;
      }
      if (!device.deviceId) {
        return DeviceStatusEnum.AUTHENTICATED;
      } else {
        return DeviceStatusEnum.ALREADY_ASSIGNED;
      }
    }
  };

  const handleCompleteClick = () => {
    hideModalHandler();
  };

  const handleDeviceClick = (deviceId) => {
    setShowDiscoveredDeviceMobileModal(true);
    const activeDevice = scannedDevices.find(
      (device) => device.channel === deviceId
    );
    setSelectedDevice(activeDevice);
    setIsFetchScannedDevices(false);
  };

  const hideDeviceRemoveModel = () => {
    setShowDiscoveredDeviceMobileModal(false);
  };

  const handleDeviceClickByDeviceId = (deviceId) => {
    const activeDevice = scannedDevices.find(
      (device) => device.deviceId === deviceId
    );
    setSelectedDevice(activeDevice);
    setIsReloadSelectedDevice(!isReloadSelectedDevice);
  };

  const handleScanNetworkClick = () => {
    if (addedDeviceCount.current >= maxDeviceCount) {
      setShowMaxLimitMsg(true);
      return;
    }
    setShowLoader(true);
    isReInitiateScanNetworkReq.current = !isReInitiateScanNetworkReq.current;
  };

  const getChannelsList = (data) => {
    const list = [];
    data.forEach((d) => {
      d.device && d.device.channels && list.push(...d.device.channels);
    });
    return list;
  };
  const DiscoveredDevicesModalHeader = useMemo(
    (footerExtraClass) =>
      ({ footerExtraClass }) =>
        (
          <div className={`header-container ${footerExtraClass}`}>
            <div className="left-section">
              <img
                src={nvrDeviceData?.imageURL}
                alt="Device"
                className="device-icon"
              />
              <div className="device-details">
                <div className="device-name deviceName-Break">
                  {nvrDeviceData?.deviceName}
                </div>
                <div className="device-added-status">
                  {addedDeviceCount.current} / {maxDeviceCount}
                  {constants.DEVICES_ADDED_TEXT}
                </div>
              </div>
            </div>
          </div>
        ),
    []
  );

  const DiscoveredDevicesModalFooter = useMemo(
    (footerExtraClass) =>
      ({ footerExtraClass }) =>
        (
          <div className={`mobileSvg footer-container ${footerExtraClass}`}>
            <Row>
              <Col className="text-start pe-0">
                <RefreshIcon
                  className={`refresh-icon ${
                    showLoader ||
                    isFetchScannedDevices ||
                    addedDeviceCount.current >= maxDeviceCount
                      ? "disabled-icon"
                      : ""
                  }`}
                />
                <PrimaryButton
                  className="scan-button text-wrap pe-0"
                  type="button"
                  width="fit-content"
                  borderWidth="0"
                  hoverBorderWidth="0"
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--primary_40")}
                  hoverColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--primary_40")}
                  disabledBackgroundColor="transparent"
                  disabledColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--greyscale_72")}
                  hoverBackgroundColor="transparent"
                  backgroundColor="transparent"
                  disabled={
                    showLoader ||
                    isFetchScannedDevices ||
                    addedDeviceCount.current >= maxDeviceCount
                  }
                  onClick={handleScanNetworkClick}
                >
                  {constants.DISCOVERED_DEVICES_SCAN_BUTTON_TEXT}
                </PrimaryButton>
              </Col>
              <Col className="text-end">
                <PrimaryButton
                  fontSize="0.875rem"
                  backgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--brand_white")}
                  height="36px"
                  width="90px"
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--primary_40")}
                  type="submit"
                  onClick={handleCompleteClick}
                >
                  {constants.COMPLETE_TEXT}
                </PrimaryButton>
              </Col>
            </Row>
          </div>
        ),
    []
  );
  const getChannelStatus = (status) => {
    switch (status) {
      case DeviceStatusEnum.AUTHENTICATED:
        return (
          <div className="status-section">
            <>
              <img src={TickOutlined} alt="TickOutlined" />
              {constants.AUTHENTICATED_STATUS}
            </>
          </div>
        );
      case DeviceStatusEnum.CON_OFFLINE:
        return (
          <div className="error-status-section">
            <>
              <ErrorIcon />
              {constants.DEVICES_OFFLINE_CONNECTION_STATUS}
            </>
          </div>
        );
      case DeviceStatusEnum.ADDED_N_CLAIMED:
        return (
          <div className="status-section">
            <>
              <img src={TickOutlined} alt="TickOutlined" />
              {constants.ADDED_CLAIMED_STATUS}
            </>
          </div>
        );
      case DeviceStatusEnum.ADDED_NOT_CLAIMED:
        return (
          <div className="pending-status-section">
            <>
              <ExclaimationIcon />
              {constants.PENDING_CLAIMED_STATUS}
            </>
          </div>
        );

      default:
        break;
    }
  };

  const fetchDeviceListForOrg = async () => {
    try {
      axios
        .get(`device/orgs/${orgDetails?.orgId}/devices`, Utils.requestHeader())
        .then(async (response) => {
          const resultData = response.data;
          if (resultData) {
            const responseMeta = resultData.meta;
            const responseData = resultData.data;
            if (
              responseMeta &&
              (responseMeta.code === 200 || responseMeta.code === "200")
            ) {
              await setDevicesListOfCurrOrg(responseData);
              setIsReloadSelectedDevice(!isReloadSelectedDevice);
            } else if (response?.response?.data?.error) {
              setErrorMsg(response?.response?.data?.error);
            }
          }
          setShowLoader(false);
        });
    } catch (error) {
      setShowLoader(false);
      console.error("ERROR: ", error);
    }
  };

  const handleErrorMsgClose = () => {
    setErrorMsg("");
  };

  const handleInfoMsgClose = () => {
    setShowMaxLimitMsg(false);
  };

  return (
    <div className={`device-container-mobile ${errorMsg && "errorMsg"}`}>
      <DiscoveredDevicesModalHeader />
      {showLoader || isFetchScannedDevices ? (
        <div className="loading-screen">
          <Loader />
          <div className="mt-4">{constants.DISCOVERED_ONVIF_DEVICES_TEXT}</div>
        </div>
      ) : actualDevices.length === 0 ? (
        <div className="loading-screen">
          <NoDeviceIcon />
          <div className="mt-4">{constants.DEVICES_NOT_FOUND_TEXT}</div>
        </div>
      ) : (
        <>
          {errorMsg ? (
            <div className="mb-4 warning-container-mobile">
              <div className="warning-message-mobile">
                <InfoCircleIcon />
                {errorMsg}
              </div>
              <RxCross1
                className="site-modal-close"
                onClick={handleErrorMsgClose}
              />
            </div>
          ) : nvrDeviceData &&
            nvrDeviceData?.deviceType?.toUpperCase() !==
              DeviceTypeEnum.DMSERVER &&
            showMaxLimitMsg &&
            addedDeviceCount.current >= maxDeviceCount ? (
            <div className="mb-4 warning-container-mobile">
              <div className="warning-message-mobile">
                <InfoCircleIcon />
                {constants.DEVICES_REACHED_MAX_LIMIT.replace(
                  "${count}",
                  maxDeviceCount
                )}
              </div>
              <RxCross1
                className="site-modal-close"
                onClick={handleInfoMsgClose}
              />
            </div>
          ) : (
            ""
          )}
          <div className="mt-2"></div>
          {selectedDevice ? (
            <div className="devices-section-mobile">
              <div className="devices-list">
                {scannedDevices.map((device, index) => (
                  <div
                    key={device.channel + index}
                    className={`device-item ${
                      device.channel === selectedDevice?.channel
                        ? "active-device"
                        : ""
                    }`}
                    onClick={() => handleDeviceClick(device.channel)}
                  >
                    <div className="item-details">
                      <div className="item-name">{device.modelNumber}</div>
                      <div className="mt-2 seconday-text">
                        {constants.IP_LABEL} {device.ipAddress}
                      </div>
                      <div className="seconday-text">
                        {constants.PORT_LABEL} {device.port}
                      </div>
                      {device.serialNo && (
                        <div className="seconday-text">
                          {constants.SERIAL_NUMBER_LABEL} {device.serialNo}
                        </div>
                      )}
                    </div>
                    <div
                      className={`item-status ${
                        device.deviceAuthStatus ===
                        DeviceStatusEnum.ALREADY_ASSIGNED
                          ? "already-assigned-status"
                          : ""
                      } ${
                        device.deviceAuthStatus ===
                          DeviceStatusEnum.AUTHENTICATED &&
                        addedDeviceCount.current >= maxDeviceCount
                          ? "disabled"
                          : ""
                      }`}
                    >
                      {device.deviceAuthStatus !==
                        DeviceStatusEnum.CON_OFFLINE &&
                        device.deviceAuthStatus !==
                          DeviceStatusEnum.ADDED_N_CLAIMED &&
                        device.deviceAuthStatus !==
                          DeviceStatusEnum.ADDED_NOT_CLAIMED &&
                        device.deviceAuthStatus}
                    </div>
                  </div>
                ))}
              </div>
              <SiteModal
                modalTitle={constants.DISCOVERED_SETUP_DEVICE}
                showModal={showDiscoveredDeviceMobileModal}
                hideModal={hideDeviceRemoveModel}
                classes="device-settings-restart-required"
              >
                <div className="device-details-mobile">
                  {loaderForSelectedDevice && <Loader />}
                  <div
                    className={`device-details-container ${
                      (selectedDevice?.deviceAuthStatus ===
                        DeviceStatusEnum.NOT_AUTHENTICATED ||
                        selectedDevice?.deviceAuthStatus ===
                          DeviceStatusEnum.AUTHENTICATED) &&
                      addedDeviceCount.current >= maxDeviceCount
                        ? "disable-form"
                        : ""
                    }`}
                  >
                    <div className="header-container">
                      <div className="title-section">
                        <Row className="text-end">
                          <Col>
                            <div className="item-name">
                              {selectedDevice?.modelNumber}
                            </div>
                          </Col>
                          <Col>
                            {getChannelStatus(selectedDevice?.deviceAuthStatus)}
                          </Col>
                        </Row>
                        <div className="mt-2 seconday-text">
                          {constants.IP_LABEL} {selectedDevice.ipAddress}
                        </div>
                        <div className="seconday-text">
                          {constants.PORT_LABEL} {selectedDevice.port}
                        </div>
                        {selectedDevice.serialNo && (
                          <div className="seconday-text">
                            {constants.SERIAL_NUMBER_LABEL}{" "}
                            {selectedDevice.serialNo}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      {selectedDevice?.deviceAuthStatus ===
                      DeviceStatusEnum.ALREADY_ASSIGNED ? (
                        <MoveDeviceControl
                          errorMsgHandler={setErrorMsg}
                          selectedDevice={selectedDevice}
                          nvrDeviceData={nvrDeviceData}
                          hubId={hubId}
                          isMobilePopup={true}
                          hideModal={hideDeviceRemoveModel}
                          deviceSelectHandler={handleDeviceClickByDeviceId}
                          selectedDeviceLoaderHandler={
                            setLoaderForSelectedDevice
                          }
                          loaderForSelectedDevice={loaderForSelectedDevice}
                        />
                      ) : selectedDevice?.deviceAuthStatus ===
                        DeviceStatusEnum.DEACTIVATED ? (
                        <ReClaimDeviceControl
                          errorMsgHandler={setErrorMsg}
                          selectedDevice={selectedDevice}
                          isMobilePopup={true}
                          hideModal={hideDeviceRemoveModel}
                          deviceSelectHandler={handleDeviceClickByDeviceId}
                          selectedDeviceLoaderHandler={
                            setLoaderForSelectedDevice
                          }
                        />
                      ) : selectedDevice?.deviceAuthStatus ===
                          DeviceStatusEnum.AUTHENTICATED ||
                        selectedDevice?.deviceAuthStatus ===
                          DeviceStatusEnum.CON_OFFLINE ||
                        selectedDevice?.deviceAuthStatus ===
                          DeviceStatusEnum.ADDED_N_CLAIMED ||
                        selectedDevice?.deviceAuthStatus ===
                          DeviceStatusEnum.ADDED_NOT_CLAIMED ? (
                        <AddAndUpdateDeviceControl
                          errorMsgHandler={setErrorMsg}
                          selectedDevice={selectedDevice}
                          nvrDeviceData={nvrDeviceData}
                          isMobilePopup={true}
                          hideModal={hideDeviceRemoveModel}
                          hubId={hubId}
                          isAddButtonDisabled={
                            selectedDevice?.deviceAuthStatus ===
                              DeviceStatusEnum.AUTHENTICATED &&
                            addedDeviceCount.current >= maxDeviceCount
                          }
                          deviceSelectHandler={handleDeviceClickByDeviceId}
                          selectedDeviceLoaderHandler={
                            setLoaderForSelectedDevice
                          }
                          fetchDeviceListForOrg={fetchDeviceListForOrg}
                          scanDeviceRequestHandler={getPublishRequest}
                          loaderForSelectedDevice={loaderForSelectedDevice}
                        />
                      ) : (
                        <NotAuthenticatedControl
                          selectedDevice={selectedDevice}
                          scannedDevices={scannedDevices}
                          hubId={hubId}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </SiteModal>
            </div>
          ) : (
            <div className="no-device-found loading-screen">
              <NoDeviceIcon />
              <div className="mt-4">{constants.DEVICES_NOT_FOUND_TEXT}</div>
            </div>
          )}
        </>
      )}
      <DiscoveredDevicesModalFooter />
    </div>
  );
};
export default DiscoveredDevicesMobile;
