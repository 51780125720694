import axios from "axios";
import moment from "moment";
import constants from "./en";
import roles from "./roleEnums";
import { ducloConfig } from "./configuration/ducloConfig";
import { megaTron } from "./configuration/megaTron";
import { AppDefaults, DeviceTypeEnum } from "./enums";
import { useLoggedInUserData } from "../store/LoggedInAccountStore";
import * as amplitude from "@amplitude/analytics-browser";
import timezones from "../data/support/timezone.json";
/**
 *
 * @param {*} organizationsList
 * @returns user role
 */
const getLoggedInUserRole = (organizationsList) => {
  let currentUserRole = "";
  let loopStatus = "";
  const roleTypes = [
    roles.ROLE4PORTAL,
    roles.ROLE5PORTAL,
    roles.ROLE2PORTAL,
    roles.ROLE1PORTAL,
    roles.ROLE3PORTAL,
    roles.ROLE6PORTAL,
    roles.ROLE7PORTAL,
  ];

  roleTypes.forEach((role, i) => {
    if (loopStatus === "stop") {
      return;
    }

    const userFound = organizationsList.filter(function (element) {
      return element.role === role;
    });

    if (userFound.length >= 1) {
      //=== If organization found with the role terminate the loop and return the role
      loopStatus = "stop";
      currentUserRole = role;
    }
  });
  return currentUserRole;
};

/**
 * Retrieves the remote URL to the account's avatar image
 *
 * @param {String} orgId - Organization Identifier
 * @param {String} accountId - Account Identifier
 * @returns
 */
const getAccountAvatarURL = async (orgId, accountId) => {
  let responseObj = {
    status: "ABORTED",
    avatarURL: "",
    errorMessage: "",
  };

  if (!orgId || !accountId) return;

  try {
    const res = await axios.get(
      `partner/orgs/${orgId}/accounts/${accountId}/imageURL`,
      {
        ...requestHeader(),
        credentials: "include",
        withCredentials: true,
      }
    );

    const responseData = res?.data;

    if (responseData?.meta?.code === 200) {
      responseObj.status = "SUCCESS";
      responseObj.avatarURL = responseData?.data?.url;
    } else {
      responseObj.status = "ERROR";
      responseObj.errorMessage = "ERROR: Unable to retrieve Avatar Image URL";
    }
  } catch (error) {
    console.error(error);
    responseObj.status = "ERROR";
    responseObj.errorMessage = error;
  } finally {
    return responseObj;
  }
};

/**
 *
 * @param {*} devicesList
 * @returns Returns count of the offline cameras
 */
const getOfflineCameras = (devicesList) => {
  let offlineCamerasCount = 0;
  if (Array.isArray(devicesList) && devicesList?.length >= 1) {
    let deviceState, connectionState;
    devicesList.forEach((device, i) => {
      deviceState = device.deviceStatus;
      connectionState = device.connectionStatus;
      if (
        deviceState === constants.DEVICES_ENTERED_DEVICE_STATUS &&
        (!connectionState ||
          connectionState === constants.DEVICES_OFFLINE_CONNECTION_STATUS ||
          connectionState === constants.DEVICES_ONLINE_CONNECTION_STATUS)
      ) {
        offlineCamerasCount++;
      } else if (
        deviceState === constants.DEVICES_REGISTERED_DEVICE_STATUS &&
        connectionState === constants.DEVICES_OFFLINE_CONNECTION_STATUS
      ) {
        offlineCamerasCount++;
      } else if (
        deviceState === constants.DEVICES_CLAIMED_DEVICE_STATUS &&
        connectionState === constants.DEVICES_OFFLINE_CONNECTION_STATUS
      ) {
        offlineCamerasCount++;
      } else if (
        deviceState === constants.DEVICES_DEACTIVATED_DEVICE_STATUS &&
        connectionState === constants.DEVICES_OFFLINE_CONNECTION_STATUS
      ) {
        offlineCamerasCount++;
      }
    });
  }
  return offlineCamerasCount;
};
const isMatchingDeviceType = (parent, child) => {
  if (parent.deviceType.toUpperCase() === DeviceTypeEnum.NVR) {
    return (
      child.gatewayId === parent.deviceId &&
      child.gatewayId !== child.deviceId &&
      child.gatewayId !== child.parentId
    );
  } else {
    return (
      child.parentId === parent.deviceId && child.parentId !== child.deviceId
    );
  }
};

const getTotalDevices = (devicesList) => {
  const totalDevice = devicesList.reduce((result, item) => {
    if (
      !item.parentId ||
      (item.parentId === item.deviceId && item.deviceId === item.gatewayId)
    ) {
      const childItems = devicesList.filter((a) =>
        isMatchingDeviceType(item, a)
      );
      if (childItems?.length > 0) {
        result.push(...childItems);
      }
    }
    return result;
  }, []);
  return totalDevice;
};

const getGroupedDevices = (deviceList) => {
  const groupedDevices = deviceList.reduce((result, item) => {
    if (
      !item.parentId ||
      (item.parentId === item.deviceId && item.deviceId === item.gatewayId)
    ) {
      const childItems = deviceList.filter((a) =>
        isMatchingDeviceType(item, a)
      );
      result.push({ ...item, childDevices: childItems });
    }
    return result;
  }, []);
  return groupedDevices;
};

/**
 *
 * @param {*} devicesList
 * @returns Returns count of the offline cameras
 */
const getUnclaimedDevices = (devicesList) => {
  let unclaimedDevicesCount = 0;
  if (devicesList.length >= 1) {
    devicesList.forEach((device, i) => {
      if (
        device.status === constants.DEVICES_REGISTERED_DEVICE_STATUS &&
        device.connectionStatus === constants.DEVICES_ONLINE_CONNECTION_STATUS
      ) {
        unclaimedDevicesCount++;
      }
    });
  }
  return unclaimedDevicesCount;
};

/**
 *
 * @param {*} licenseList
 * @returns Returns count of the liecenses expiring (Note: this is not done yet waiting for API to be update)
 */
const getLicensesExpiring = (licenseList) => {
  return 0;
};

/**
 *
 * @param {*} policiesList
 * @returns Returns the updated policies list based logged in user policies
 */
const mapUserPolicies = (allPoliciesList, userPoliciesList) => {
  let newPolicies = Object.assign({}, allPoliciesList);
  if (checkObjectEmpty(newPolicies) && userPoliciesList?.length >= 1) {
    userPoliciesList.forEach((policy, i) => {
      let policyName = policy.policyName;
      newPolicies[policyName] = true;
    });
    return newPolicies;
  }
  return newPolicies;
};

/**
 * Compares two arrays of objects for equality
 *
 * @param {Array} array1
 * @param {Array} array2
 * @returns {Boolean}
 */
const areArraysOfObjectsEqual = (array1 = [], array2 = []) => {
  // Check if the arrays have the same length
  if (array1.length !== array2.length) {
    return false;
  }

  // Compare each object in the arrays
  for (let i = 0; i < array1.length; i++) {
    // Check if the objects have the same number of properties
    if (Object.keys(array1[i]).length !== Object.keys(array2[i]).length) {
      return false;
    }

    // Compare the properties of each object
    for (let key in array1[i]) {
      if (array1[i][key] !== array2[i][key]) {
        return false;
      }
    }
  }

  return true;
};

/**
 *
 * @param {*} object variable
 * @returns Returns if passed value is object and not empty
 */
const checkObjectEmpty = (objVar) => {
  if (
    objVar && // 👈 null and undefined check
    Object.keys(objVar).length >= 1 &&
    Object.getPrototypeOf(objVar) === Object.prototype
  ) {
    return true;
  }
  return false;
};

/**
 *
 * @returns Returns true/false if view is mobile view or not
 */
const checkIfMobileView = () => {
  const currentWidth = window.innerWidth;
  return currentWidth <= 768 ? true : false;
};

/**
 *
 * @param {String} price - display price
 * @returns {JSX} - JSX element to render
 */
const formatCurrencyWithSmallDecimals = (price) => {
  let separator = price.indexOf(".") !== -1 ? "." : ",";
  let parts = price.split(separator);

  if (!Array.isArray(parts) || parts.length < 2) {
    parts = ["$0", `${separator}00`];
  }

  return (
    <>
      <span>{parts[0]}</span>
      <span className="small-decimals">.{parts[1]}</span>
    </>
  );
};

/**
 *
 * @param {string} featureType - feature type
 * @param {Object} product - product object
 * @returns {*} - value of specified feature
 */
const getFeatureValue = (featureType, product) => {
  if (
    !product ||
    !Array.isArray(product.featureTypes) ||
    !Array.isArray(product.features)
  ) {
    return 0;
  }

  const featureTypeIndex = product.featureTypes.findIndex((ft) => {
    return ft.featureType === featureType;
  });

  if (featureTypeIndex !== -1) {
    const featureTypeId = product.featureTypes[featureTypeIndex].featureTypeId;

    const featureIndex = product.features.findIndex((feature) => {
      return feature.featureTypeId === featureTypeId;
    });

    if (featureIndex !== -1) {
      // Add specific feature type to query in this switch statement
      switch (featureType) {
        case "SERVICE_DURATION":
          return product.features[featureIndex].durationInDays;

        default:
          return product.features[featureIndex].featureName;
      }
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

/**
 *
 * @param {*} Date
 * @returns Returns unix date
 */
const getUnixDate = (date) => {
  return moment(new Date(date)).unix();
};
/**
 *
 * @param {*} Date
 * @returns Returns unix date
 */
const fetchDateInUnix = (date) => {
  const input = new Date(date * 1000); // Convert UNIX timestamp to milliseconds
  input.setUTCHours(0, 0, 0, 0); // Set the time to midnight
  const output = Math.floor(input.getTime() / 1000); // Convert milliseconds back to UNIX timestamp
  return output;
};
/**
 *
 * @param {*} UnixTimestamp
 * @returns Returns original date
 */
const getDate = (unixDate) => {
  return moment.unix(unixDate).toDate();
};

/**
 *
 * @param {Number} startUnixTime
 * @param {Number} endUnixTime
 * @returns Returns the difference between the two unix times in number of days
 */
const getDateDifferenceInDays = (startUnixTime, endUnixTime) => {
  if (isNaN(startUnixTime) || isNaN(endUnixTime)) return 0;

  const startTime = moment(startUnixTime);
  const endTime = moment(endUnixTime);

  if (!startTime || !endTime) return 0;

  return Math.ceil(moment.duration(startTime?.diff(endTime)).asDays());
};

/**
 * Retrieves the starting and ending offsets in pixels relative
 * to a midpoint location on the x-axis.
 *
 * @param {Number} absoluteMidPointLocation - midpoint location along the
 * video player timeline
 * @param {Number} beforeLocation - starting location of clipper on x-axis
 * @param {Number} clipperWidth - length of clipper in pixels
 *
 * @returns {Object|Null} before and after offsets in pixels
 */
const calculateBeforeAndAfterOffsets = (
  absoluteMidPointLocation,
  beforeLocation,
  clipperWidth
) => {
  const midPoint = absoluteMidPointLocation;
  let startOffset,
    shouldAddStartOffset,
    endOffset,
    shouldAddEndOffset,
    endLocation,
    beforeAndAfterOffsets;

  try {
    if (isNaN(beforeLocation) || isNaN(midPoint) || isNaN(clipperWidth)) {
      throw new Error(
        "ERROR: missing required parameters for calculateBeforeAndAfterOffsets()"
      );
    }

    // Calculate the end point's location on timeline
    endLocation = parseInt(beforeLocation) + parseInt(clipperWidth);

    // Calculate Start Offset
    if (midPoint > beforeLocation) {
      startOffset =
        midPoint -
        (beforeLocation + AppDefaults.INCIDENT_EVIDENCE_CLIP_HANDLE_WIDTH);
      shouldAddStartOffset = false;
    } else {
      startOffset =
        beforeLocation -
        (midPoint + AppDefaults.INCIDENT_EVIDENCE_CLIP_HANDLE_WIDTH);
      shouldAddStartOffset = true;
    }

    // Calculate End Offset
    if (midPoint > endLocation) {
      endOffset =
        midPoint -
        (endLocation - AppDefaults.INCIDENT_EVIDENCE_CLIP_HANDLE_WIDTH);
      shouldAddEndOffset = false;
    } else {
      endOffset =
        endLocation -
        (midPoint + AppDefaults.INCIDENT_EVIDENCE_CLIP_HANDLE_WIDTH);
      shouldAddEndOffset = true;
    }

    beforeAndAfterOffsets = {
      startOffset,
      shouldAddStartOffset,
      endOffset,
      shouldAddEndOffset,
    };
  } catch (error) {
    beforeAndAfterOffsets = null;
    console.error(error);
  } finally {
    return beforeAndAfterOffsets;
  }
};

/**
 * Calculates the before and after times relative to a reference time
 *
 * @param {Number} referenceUnixTime - the reference point in time
 * @param {Number} beforeOffset - number of seconds before the reference time
 * @param {Boolean} addBeforeOffset - should the before offset be added to the
 * reference unix time
 * @param {Number} afterOffset - the number of seconds after the reference time
 * @param {Boolean} addAfterOffset - should the after offset be added to the
 * reference unix time
 * @returns {Object} beforeAndAfterUnixTimes - before and after time values
 * in unix time format
 */
const getBeforeAndAfterUnixTimes = (
  referenceUnixTime,
  beforeOffset,
  addBeforeOffset,
  afterOffset,
  addAfterOffset
) => {
  let beforeAndAfterUnixTimes = null;
  let timeBefore, timeAfter;

  try {
    if (isNaN(referenceUnixTime) || isNaN(beforeOffset) || isNaN(afterOffset)) {
      throw new Error(
        "ERROR: missing required parameters for getBeforeAndAfterUnixTimes()"
      );
    }

    // Convert UNIX time to moment object
    const currentTime = moment.unix(referenceUnixTime);

    // Calculate time beforeOffset seconds before the reference time
    if (addBeforeOffset) {
      timeBefore = currentTime.clone().add(beforeOffset, "seconds");
    } else {
      timeBefore = currentTime.clone().subtract(beforeOffset, "seconds");
    }
    const timeBeforeUNIX = timeBefore.valueOf();

    // Calculate time afterOffset seconds after the reference time
    if (addAfterOffset) {
      timeAfter = currentTime.clone().add(afterOffset, "seconds");
    } else {
      timeAfter = currentTime.clone().subtract(afterOffset, "seconds");
    }
    const timeAfterUNIX = timeAfter.valueOf();

    beforeAndAfterUnixTimes = {
      beforeTime: timeBefore,
      beforeUnixTime: timeBeforeUNIX,
      afterTime: timeAfter,
      afterUnixTime: timeAfterUNIX,
    };
  } catch (error) {
    console.error(error);
  } finally {
    return beforeAndAfterUnixTimes;
  }
};

/**
 *
 * @param {*} device status and connection status
 * @returns Returns UX status
 */
const getDeviceStatus = (deviceStatus, connectionStatus) => {
  if (
    deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS &&
    connectionStatus === constants.DEVICES_OFFLINE_CONNECTION_STATUS
  ) {
    return constants.DEVICES_RETURN_OFFLINE_STATUS;
  } else if (deviceStatus === constants.DEVICES_DEACTIVATED_DEVICE_STATUS) {
    return constants.DEVICES_RETURN_DEACTIVATED_STATUS;
  } else if (
    (deviceStatus === constants.DEVICES_ENTERED_DEVICE_STATUS ||
      deviceStatus === constants.DEVICES_REGISTERED_DEVICE_STATUS) &&
    (connectionStatus === constants.DEVICES_OFFLINE_CONNECTION_STATUS ||
      connectionStatus === undefined ||
      connectionStatus === null)
  ) {
    return constants.DEVICES_RETURN_ENTERED_STATUS;
  } else if (
    deviceStatus === constants.DEVICES_REGISTERED_DEVICE_STATUS &&
    connectionStatus === constants.DEVICES_ONLINE_CONNECTION_STATUS
  ) {
    return constants.DEVICES_RETURN_READY_TO_CLAIM_STATUS;
  } else if (
    deviceStatus === constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS &&
    connectionStatus === constants.DEVICES_OFFLINE_CONNECTION_STATUS
  ) {
    return constants.DEVICES_RETURN_CLAIMING_STATUS;
  } else if (
    deviceStatus === constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS &&
    connectionStatus === constants.DEVICES_ONLINE_CONNECTION_STATUS
  ) {
    return constants.DEVICES_RETURN_CLAIMING_STATUS;
  } else if (
    deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS &&
    connectionStatus === constants.DEVICES_ONLINE_CONNECTION_STATUS
  ) {
    return constants.DEVICES_RETURN_ONLINE_STATUS;
  } else if (
    !connectionStatus &&
    deviceStatus === constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS
  ) {
    return constants.DEVICES_RETURN_CLAIMING_STATUS;
  } else {
    return constants.DEVICES_RETURN_OFFLINE_STATUS;
  }
};

/**
 *
 * @param {*} string, string replace key, string replace value
 * @returns Returns string
 */
const replaceStringValues = (str, key, value) => {
  return str?.replace(key, value);
};

const encodeImageFileToBase64String = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const decodeBase64StringToImageFile = (string64, fileName) => {
  const trimmedString = string64.replace("dataimage/jpegbase64", "");
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);
  const type = "image/jpeg";
  const blob = new Blob([buffer], { type });

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type,
  });
};

//this will return datevalue in millisecond
const daysToMilliseconds = (dateValue) => {
  return (dateValue + 1) * 24 * 60 * 60 * 1000;
};

//Format Mac Address with colon
const formatMACAddress = (e) => {
  const r = /([a-f0-9]{2})([a-f0-9]{2})/i;
  let str = e.replace(/[^a-f0-9]/gi, "");

  while (r.test(str)) {
    str = str.replace(r, "$1" + ":" + "$2");
  }

  return (e = str.slice(0, 17));
};

const getCurrentTabProperty = (data, tabresourcse) => {
  const deviceProperty = data?.find(
    (device) => device.resource === tabresourcse
  );

  return deviceProperty?.properties;
};

const getTagsCategoryAvilable = () => {
  const tags = ["person", "face", "license plate", ""];
};

const getPropertyShowStatus = (
  deviceStatus,
  propertyName,
  capabilityProperty
) => {
  if (deviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS) {
    return (
      capabilityProperty && capabilityProperty.hasOwnProperty(propertyName)
    );
  } else {
    return true;
  }
};

const getDeviceSettingResource = (tabIndex) => {
  const deviceSettingResources = {
    0: ["camera/system/device-info", "camera/system/date"],
    1: [
      "camera/image/rotate",
      "camera/image/wdr",
      "camera/media/video-profile",
      "camera/media/wisestream",
      "camera/image/ir-mode",
      "camera/image/image-enhancement",
    ],
    2: ["camera/image/focus"],
    3: ["camera/media/audio-input", "camera/media/audio-output"],
    5: ["camera/network/ip-support"],
    6: [
      "camera/diag/restart",
      "camera/diag/full-reset",
      "camera/diag/duclo-fw-update",
      "camera/diag/device-fw-update",
      "diag/uploadLogs",
    ],
    7: [
      "camera/event/object-detection",
      "camera/event/motion-detection",
      "camera/event/shock-detection",
      "camera/event/tamper-detection",
      "camera/event/defocus-detection",
      "camera/event/audio-detection",
      "camera/event/virtual-line",
    ],
    8: ["camera/settings/cvr"],
    9: ["camera/settings/max-br"],
    10: ["camera/system/ptz", "camera/system/fisheye"],
    11: ["hub/channels", "hub/channels/auth-creds"],
  };
  if (isNaN(tabIndex) || tabIndex < 0 || tabIndex > 11) {
    return;
  } else {
    return deviceSettingResources[tabIndex];
  }
};

const getDropDownPair = (dataList) => {
  let dropDownList = [];
  if (dataList) {
    for (var i of dataList) {
      dropDownList.push({ label: i, value: i });
    }
  }
  return dropDownList;
};

const getPropertyValueIfExist = (value, isMax) => {
  if (isNaN(value)) {
    return 1;
  } else if (
    value?.toLowerCase() === constants.MEDIUM_VALUE_LABEL.toLowerCase()
  ) {
    return 2;
  } else if (value?.toLowerCase() === constants.MAX_VALUE_LABEL.toLowerCase()) {
    return 3;
  } else if (value?.toLowerCase() === constants.MIN_VALUE_LABEL.toLowerCase()) {
    return 1;
  } else if ((isMax || !isMax) && value) {
    return parseInt(value);
  } else if (isMax) {
    return 100;
  } else {
    return 1;
  }
};

const getCodecAvailability = (valueCodec, valueData) => {
  const codecStatus = valueData?.find(
    (codecData) => codecData.toUpperCase() === valueCodec.toUpperCase()
  );
  return codecStatus;
};

const getDetectionValue = (modalIndex, properties, modalData) => {
  if (isNaN(modalIndex) || modalIndex < 0 || modalIndex > 5) {
    return;
  }
  let detectionValue;
  switch (modalIndex) {
    case 1:
      detectionValue = {
        ...modalData,
        valueLevelDetection: properties?.["md-level"],
        valueSensitivity: properties?.["md-sensitivity"],
        valueDuration: properties?.["md-min-dur"],
      };
      break;
    case 2:
      detectionValue = {
        ...modalData,
        valueLevelDetection: properties?.["skd-level"],
        valueSensitivity: properties?.["skd-sensitivity"],
        valueDuration: properties?.["skd-min-dur"],
      };
      break;
    case 3:
      detectionValue = {
        ...modalData,
        valueLevelDetection: properties?.["td-level"],
        valueSensitivity: properties?.["td-sensitivity"],
      };
      break;
    case 4:
      detectionValue = {
        ...modalData,
        valueLevelDetection: properties?.["dfd-level"],
        valueSensitivity: properties?.["dfd-sensitivity"],
        valueDuration: properties?.["dfd-min-dur"],
      };
      break;
    case 5:
      detectionValue = {
        ...modalData,
        valueLevelDetection: properties?.["audio-level"],
      };
      break;
    default:
  }
  return detectionValue;
};

const getModalData = (modalIndex, properties, modalData, resource) => {
  if (isNaN(modalIndex) || modalIndex < 0 || modalIndex > 5) {
    return;
  }
  let getModalValues;
  switch (modalIndex) {
    case 1:
      getModalValues = {
        maxLevelDetection: getPropertyValueIfExist(
          modalData?.["md-level"]?.max,
          true
        ),
        minLevelDetection: getPropertyValueIfExist(
          modalData?.["md-level"]?.min,
          false
        ),
        valueLevelDetection: getPropertyValueIfExist(
          properties?.["md-level"],
          false
        ),
        maxSensitivity: getPropertyValueIfExist(
          modalData?.["md-sensitivity"]?.max,
          true
        ),
        minSensitivity: getPropertyValueIfExist(
          modalData?.["md-sensitivity"]?.min,
          false
        ),
        valueSensitivity: getPropertyValueIfExist(
          properties?.["md-sensitivity"],
          false
        ),
        maxDuration: getPropertyValueIfExist(
          modalData?.["md-min-dur"]?.max,
          true
        ),
        minDuration: getPropertyValueIfExist(
          modalData?.["md-min-dur"]?.min,
          false
        ),
        valueDuration: getPropertyValueIfExist(
          properties?.["md-min-dur"],
          false
        ),
        levelKey: "md-level",
        sensitivityKey: "md-sensitivity",
        mindurationKey: "md-min-dur",
        resource: resource,
      };
      break;
    case 2:
      getModalValues = {
        maxLevelDetection: getPropertyValueIfExist(
          modalData?.["skd-level"]?.max,
          true
        ),
        minLevelDetection: getPropertyValueIfExist(
          modalData?.["skd-level"]?.min,
          false
        ),
        valueLevelDetection: getPropertyValueIfExist(
          properties?.["skd-level"],
          false
        ),
        maxSensitivity: getPropertyValueIfExist(
          modalData?.["skd-sensitivity"]?.max,
          true
        ),
        minSensitivity: getPropertyValueIfExist(
          modalData?.["skd-sensitivity"]?.min,
          false
        ),
        valueSensitivity: getPropertyValueIfExist(
          properties?.["skd-sensitivity"],
          false
        ),
        levelKey: "skd-level",
        sensitivityKey: "skd-sensitivity",
        resource: resource,
      };
      break;
    case 3:
      getModalValues = {
        maxLevelDetection: getPropertyValueIfExist(
          modalData?.["td-level"]?.max,
          true
        ),
        minLevelDetection: getPropertyValueIfExist(
          modalData?.["td-level"]?.min,
          false
        ),
        valueLevelDetection: getPropertyValueIfExist(
          properties?.["td-level"],
          false
        ),
        maxSensitivity: getPropertyValueIfExist(
          modalData?.["td-sensitivity"]?.max,
          true
        ),
        minSensitivity: getPropertyValueIfExist(
          modalData?.["td-sensitivity"]?.min,
          false
        ),
        valueSensitivity: getPropertyValueIfExist(
          properties?.["td-sensitivity"],
          false
        ),
        maxDuration: getPropertyValueIfExist(
          modalData?.["td-min-dur"]?.max,
          true
        ),
        minDuration: getPropertyValueIfExist(
          modalData?.["td-min-dur"]?.min,
          false
        ),
        valueDuration: getPropertyValueIfExist(
          properties?.["td-min-dur"],
          false
        ),
        levelKey: "td-level",
        sensitivityKey: "td-sensitivity",
        mindurationKey: "td-min-dur",
        resource: resource,
      };
      break;
    case 4:
      getModalValues = {
        maxLevelDetection: getPropertyValueIfExist(
          modalData?.["dfd-level"]?.max,
          true
        ),
        minLevelDetection: getPropertyValueIfExist(
          modalData?.["dfd-level"]?.min,
          false
        ),
        valueLevelDetection: getPropertyValueIfExist(
          properties?.["dfd-level"],
          false
        ),
        maxSensitivity: getPropertyValueIfExist(
          modalData?.["dfd-sensitivity"]?.max,
          true
        ),
        minSensitivity: getPropertyValueIfExist(
          modalData?.["dfd-sensitivity"]?.min,
          false
        ),
        valueSensitivity: getPropertyValueIfExist(
          properties?.["dfd-sensitivity"],
          false
        ),
        maxDuration: getPropertyValueIfExist(
          modalData?.["dfd-min-dur"]?.max,
          true
        ),
        minDuration: getPropertyValueIfExist(
          modalData?.["dfd-min-dur"]?.min,
          false
        ),
        valueDuration: getPropertyValueIfExist(
          properties?.["dfd-min-dur"],
          false
        ),
        levelKey: "dfd-level",
        sensitivityKey: "dfd-sensitivity",
        mindurationKey: "dfd-min-dur",
        resource: resource,
      };
      break;
    case 5:
      getModalValues = {
        maxLevelDetection: getPropertyValueIfExist(
          modalData?.["audio-level"]?.max,
          true
        ),
        minLevelDetection: getPropertyValueIfExist(
          modalData?.["audio-level"]?.min,
          false
        ),
        valueLevelDetection: getPropertyValueIfExist(
          properties?.["audio-level"],
          false
        ),
        levelKey: "audio-level",
        resource: resource,
      };
      break;
    default:
  }
  return getModalValues;
};

const getWeekDays = (currentDay) => {
  if (!Array.isArray(currentDay) || currentDay?.length === 0) {
    return;
  }
  return [
    {
      dayPrefix: "S",
      id: 1,
      day: "Sunday",
      isSelected: currentDay?.find((day) => day === "1" || day === 1)
        ? true
        : false,
      shortName: "Sun",
    },
    {
      dayPrefix: "M",
      id: 2,
      day: "Monday",
      isSelected: currentDay?.find((day) => day === "2" || day === 2)
        ? true
        : false,
      shortName: "Mon",
    },
    {
      dayPrefix: "T",
      id: 3,
      day: "Tuesday",
      isSelected: currentDay?.find((day) => day === "3" || day === 3)
        ? true
        : false,
      shortName: "Tue",
    },
    {
      dayPrefix: "W",
      id: "4",
      day: "Wednesday",
      isSelected: currentDay?.find((day) => day === "4" || day === 4)
        ? true
        : false,
      shortName: "Wed",
    },
    {
      dayPrefix: "T",
      id: 5,
      day: "Thusday",
      isSelected: currentDay?.find((day) => day === "5" || day === 5)
        ? true
        : false,
      shortName: "Thu",
    },
    {
      dayPrefix: "F",
      id: 6,
      day: "Friday",
      isSelected: currentDay?.find((day) => day === "6" || day === 6)
        ? true
        : false,
      shortName: "Fri",
    },
    {
      dayPrefix: "S",
      id: 7,
      day: "Saturday",
      isSelected: currentDay?.find((day) => day === "7" || day === 7)
        ? true
        : false,
      shortName: "Sat",
    },
  ];
};

const getStartEndMin = (cDate, isStartTime) => {
  let hour = Math.floor(new Date(cDate).getHours());
  let min = Math.floor(new Date(cDate).getMinutes());
  let startTime = hour * 60 + min;
  if (isStartTime) {
    return startTime;
  } else {
    return startTime > 1440 ? startTime - 1440 : startTime;
  }
};

const getHoursFromValue = (time) => {
  if (isNaN(time)) {
    return;
  }

  let h = Math.floor(time / 60);

  let m = time % 60;

  h = h < 10 ? "0" + h : h;

  m = m < 10 ? "0" + m : m;

  return h + ":" + m;
};

const getTimeStampFromDate = (timeHrSec) => {
  const time = getUnixDate(
    new Date(moment(new Date())).toDateString(`yyyy-MM-dd${timeHrSec}`)
  );
  return time * 1000;
};

const getPastDaysTime = (timeHrSec, days) => {
  const time = new Date(
    moment
      .tz(moment().subtract(days, "days"), moment.tz.guess())
      .format("MMM DD, YYYY") +
      " " +
      timeHrSec
  ).getTime();
  return time;
};

const getHourPast = (days) => {
  const time = new Date(
    moment
      .tz(moment().subtract(days, "days"), moment.tz.guess())
      .format("MMM DD, YYYY hh:mm:ss a")
  ).getTime();
  return time;
};

const getPastWeekDayName = (days) => {
  const weekDayName = moment(
    new Date(moment.tz(moment().subtract(days, "days"), moment.tz.guess()))
  ).format("dddd");
  return weekDayName;
};

const getHourPastByValue = (hoursValue) => {
  const time = new Date(
    moment
      .tz(moment().subtract({ hours: hoursValue }), moment.tz.guess())
      .format("MMM DD, YYYY hh:mm:ss a")
  ).getTime();
  return time;
};

const getTimeFilters = () => {
  const time = [
    { id: 0, value: "All time" },
    { id: 2, value: "Past Hour" },
    { id: 1, value: "Past 24 Hour" },
    { id: 7, value: "Last 7 days" },
    { id: 15, value: "Last 15 days" },
    { id: 30, value: "Last 30 days" },
    { id: 8, value: "Custom Range" },
  ];
  return time;
};

const getHourFromEpoch = (milliseconds) => {
  if (isNaN(milliseconds)) {
    return;
  }

  return moment(milliseconds).format("hh:mm");
};

/**
 * Converts a time in milliseconds to Date string
 *
 * @param {Number} milliseconds
 * @returns Date string in MM/DD format
 */
const getDateFromEpoch = (milliseconds) => {
  if (isNaN(milliseconds)) {
    return;
  }

  return moment.utc(milliseconds).startOf("day").format("MM/DD");
};

const getWeekFromEpoch = (milliseconds) => {
  if (isNaN(milliseconds)) {
    return;
  }

  return moment.utc(milliseconds).startOf("day").format("MM/DD");
};

const getMonthFromEpoch = (milliseconds) => {
  if (isNaN(milliseconds)) {
    return;
  }

  return moment.utc(milliseconds).format("MMM");
};

const getYearFromEpoch = (milliseconds) => {
  if (isNaN(milliseconds)) {
    return;
  }

  return moment.utc(milliseconds).startOf("day").format("YYYY");
};

/**
 *
 * @param {Number} dayRange - target number of days
 * @param {*} endDateMilliseconds - end date in milliseconds (Epoch)
 * @returns Array of past number of days in milliseconds
 */
const getPastDaysInMilliseconds = (dayRange, endDateMilliseconds) => {
  const pastDays = [];

  if (isNaN(dayRange) || isNaN(endDateMilliseconds)) {
    return;
  }

  for (let i = 0; i < dayRange; i++) {
    // loop to subtract 1 day in each iteration
    const pastDate = moment(endDateMilliseconds).subtract(i, "days").valueOf();
    pastDays.push(pastDate);
  }

  return pastDays;
};

/**
 * Retrieve area bane from devices array given an area ID
 * @param {*} devices
 * @param {*} areaId
 * @returns
 */
const getAreaName = (devices, areaId) => {
  let area;
  if (!devices || !Array.isArray(devices) || !areaId) {
    return;
  }

  area = devices.find((device) => device.areaId === areaId);
  return area ? area?.areas[0]?.areaName : null;
};

/**
 * Retrieve location name from devices array given a location ID
 * @param {Array} devices
 * @param {String} locationId
 * @returns {String} name of location
 */
const getLocationName = (devices, locationId) => {
  let locationDevice;

  if (!devices || !Array.isArray(devices) || !locationId) {
    return;
  }

  locationDevice = devices.find((device) => device?.locationId === locationId);

  return locationDevice ? locationDevice?.locationName : null;
};

const searchCategoryText = (requestTag, categoryText, type) => {
  let isCatItem = false;
  const tagColor =
    type === 1
      ? requestTag?.tags?.person?.pantColors
      : type === 2
      ? requestTag?.tags?.person?.shirtColors
      : type === 3 && requestTag?.tags?.vehicle?.extColors;
  if (tagColor && tagColor?.length > 0) {
    isCatItem = tagColor.includes(categoryText);
  }
  return isCatItem;
};

const searchCategoryReqBody = (requestTag, categoryText) => {
  let isCatItem = false;
  if (requestTag && requestTag?.length > 0) {
    if (categoryText.indexOf("vehicletype") >= 0) {
      let car = categoryText.replace("vehicletype", getCategoryText().CAR);
      let truck = categoryText.replace("vehicletype", getCategoryText().TRUCK);
      let bicycle = categoryText.replace(
        "vehicletype",
        getCategoryText().BICYCLE
      );
      let motercycle = categoryText.replace(
        "vehicletype",
        getCategoryText().MOTERCYCLE
      );
      let bus = categoryText.replace("vehicletype", getCategoryText().BUS);
      for (let i = 0; i < requestTag.length; i++) {
        if (requestTag[i] === car) {
          isCatItem = true;
          break;
        } else if (requestTag[i] === bicycle) {
          isCatItem = true;
          break;
        } else if (requestTag[i] === truck) {
          isCatItem = true;
          break;
        } else if (requestTag[i] === motercycle) {
          isCatItem = true;
          break;
        } else if (requestTag[i] === bus) {
          isCatItem = true;
          break;
        }
      }
    } else {
      isCatItem = requestTag.includes(categoryText);
    }
  }
  return isCatItem;
};

const getCommonColor = (requestTag, category, type) => {
  const colors = [
    {
      name: "green",
      id: 0,
      value: "#4EA65A",
      isSelected: searchCategoryText(requestTag, `green`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `green ${category}`),
    },
    {
      name: "yellow",
      id: 1,
      value: "#E5C261",
      isSelected: searchCategoryText(requestTag, `yellow`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `yellow ${category}`),
    },
    {
      name: "orange",
      id: 2,
      value: "#E58161",
      isSelected: searchCategoryText(requestTag, `orange`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `orange ${category}`),
    },
    {
      name: "red",
      id: 3,
      value: "#E56161",
      isSelected: searchCategoryText(requestTag, `red`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `red ${category}`),
    },
    {
      name: "blue",
      id: 4,
      value: "#61A6E5",
      isSelected: searchCategoryText(requestTag, `blue`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `blue ${category}`),
    },
    {
      name: "purple",
      id: 5,
      value: "#7461E5",
      isSelected: searchCategoryText(requestTag, `purple`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `purple ${category}`),
    },
    {
      name: "gray",
      id: 6,
      value: "#7D8BA1",
      isSelected: searchCategoryText(requestTag, `gray`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `gray ${category}`),
    },
    {
      name: "white",
      id: 7,
      value: "#FFFFFF",
      isSelected: searchCategoryText(requestTag, `white`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `white ${category}`),
    },
    {
      name: "black",
      id: 8,
      value: "#111111",
      isSelected: searchCategoryText(requestTag, `black`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `black ${category}`),
    },
  ];
  return colors;
};

const getCategoryName = () => {
  const categoryName = {
    PERSON: "person",
    FACE: "face",
    VEHICLE: "vehicle",
    LICENSEPLATE: "licensePlate",
  };
  return categoryName;
};

const getCategoryText = () => {
  const categoryName = {
    ADUlT: "adult age",
    MIDDLE: "middle age",
    SENIOR: "senior age",
    YOUNG: "young age",
    MALE: "male gender",
    FEMALE: "female gender",
    OPTICAL: "opticals",
    HAT: "hat",
    MASK: "mask",
    BAG: "bag",
    TRUCK: "truck",
    CAR: "car",
    BUS: "bus",
    BICYCLE: "bicycle",
    MOTERCYCLE: "motorcycle",
    BIKE: "bike",
    GLASSES: "glasses",
  };
  return categoryName;
};

const generateUUID = () => {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

const getNotificationResources = () => {
  const resource = {
    person: "camera/event/object-detection",
    vehicle: "camera/event/object-detection",
    camera: "camera/event/defocus-detection",
    loitering: "camera/event/virtual-area",
    tempering: "camera/event/tamper-detection",
    sound: "camera/event/sound-classification",
    motion: "camera/event/motion-detection",
    fog: "camera/event/fog-detection",
  };
  return resource;
};

const getNotificationEventName = () => {
  const events = {
    person: "person",
    vehicle: "vehicle",
    motionStart: "motion-start",
    motionEnd: "motion-end",
    defocusStart: "defocus-start",
    tamperStart: "tamper-start",
    cameraAdd: "camera-add",
    loitering: "loitering",
    gunShot: "gun-shot",
    scream: "scream",
    explosion: "explosion",
    glassBreaking: "glass-breaking",
    cameraOnline: "camera-online",
    cameraOffline: "camera-offline",
  };
  return events;
};

const getModuleStatus = () => {
  const config =
    process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
      ? megaTron
      : ducloConfig;
  return config;
};

const moveArrayPosition = (arr, old_index, new_index) => {
  try {
    if (arr.length > 0) {
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    }
  } catch (err) {}
};

const detectBrowser = () => {
  let isMobile = false;
  const ismobile =
    /iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile/i.test(
      navigator.userAgent.toLowerCase()
    );
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      navigator.userAgent.toLowerCase()
    );
  if (isTablet) {
    isMobile = false;
  } else if (ismobile) {
    isMobile = true;
  } else {
    isMobile = false;
  }
  return isMobile;
};

const requestHeader = (corId) => {
  return {
    headers: {
      "X-Correlation-ID": corId || generateUUID(),
    },
  };
};

const CheckLoggedInUserRole = (orgId, orgName) => {
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();
  let defaultBread;
  if (
    loggedInUser?.role !== roles.ROLE4PORTAL &&
    loggedInUser?.role !== roles.ROLE6PORTAL &&
    loggedInUser?.role !== roles.ROLE5PORTAL
  ) {
    let concatOrgName = orgName ? `&orgName=${orgName}&fromPush=true` : "";
    defaultBread = {
      url: `/landing-page.html?orgId=${orgId}${concatOrgName}`,
      title: constants.LANDING_PAGE_TITLE,
    };
  } else {
    defaultBread = {
      url: `/customers/manage.html`,
      title:
        loggedInUser.role !== roles.ROLE2PORTAL &&
        loggedInUser.role !== roles.ROLE7PORTAL
          ? constants.MANAGE_ORG_PAGE_TITLE
          : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    };
  }
  return defaultBread;
};
const CheckBreadcrumbForUserRole = (list) => {
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();
  if (
    loggedInUser?.role !== roles.ROLE4PORTAL &&
    loggedInUser?.role !== roles.ROLE6PORTAL &&
    loggedInUser?.role !== roles.ROLE5PORTAL
  ) {
    return list.slice(1);
  }
  return list;
};
const getManufacturer = (name) => {
  if (name === constants.ADD_DEVICE_MANUFRACTURE_NAME_TECHWIN) {
    return constants.ADD_DEVICE_MANUFRACTURE_NAME;
  }
  return constants.ADD_DEVICE_MANUFRACTURE_NAME;
};
const checkNotificationSupport = () => {
  return (
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window
  );
};

const isNullString = (stringValue) => {
  if (stringValue !== "" && stringValue !== null && stringValue !== undefined) {
    return false;
  } else {
    return true;
  }
};
const amplitudeInit = (accountId) => {
  const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
  amplitude.setSessionId(Date.now());
  return amplitude.init(apiKey, accountId, {
    defaultTracking: true,
    pageViews: true,
    sessions: true,
    formInteractions: true,
    fileDownloads: true,
  });
};
const amplitudeTrack = (AmplitudeEvent, eventProperties = "") => {
  if (eventProperties !== "") {
    return amplitude.track(AmplitudeEvent, eventProperties);
  } else {
    return amplitude.track(AmplitudeEvent);
  }
};
// All parent devices
const getTotalParentDevices = (devices) => {
  if (!devices) {
    return [];
  }
  const totalDevice = devices.reduce((result, item) => {
    if (item.parentId === item.deviceId && item.deviceId === item.gatewayId) {
      result.push(item);
    }
    return result;
  }, []);
  return totalDevice;
};
// All child devices
const getTotalChildDevices = (devices) => {
  if (!devices) {
    return [];
  }
  const totalDevice = devices.reduce((result, item) => {
    if (item.parentId === item.deviceId && item.deviceId === item.gatewayId) {
      const childItems = devices.filter((a) => isMatchingDeviceType(item, a));
      if (childItems?.length > 0) {
        result.push(...childItems);
      }
    }
    return result;
  }, []);
  return totalDevice;
};

const compareObjectLists = (array1, array2) => {
  if (array1?.length !== array2?.length) {
    return false;
  }
  const sortedArray1 = array1
    ?.slice()
    .sort((a, b) => a.orgId.localeCompare(b.orgId));
  const sortedArray2 = array2
    ?.slice()
    .sort((a, b) => a.orgId.localeCompare(b.orgId));
  for (let i = 0; i < sortedArray1?.length; i++) {
    const obj1 = sortedArray1[i];
    const obj2 = sortedArray2[i];
    const objStr1 = JSON.stringify(obj1);
    const objStr2 = JSON.stringify(obj2);
    if (objStr1 !== objStr2) {
      return false;
    }
  }
  return true;
};

const findTimeZoneFromSelectedValue = (selectedValue) => {
  try {
    const timeZoneList = timezones?.data;
    const selectedTimeZone = timeZoneList?.find(
      (zone) => zone.location === selectedValue || zone.value === selectedValue
    );
    return selectedTimeZone;
  } catch (err) {
    console.log("Error while finding timezone", err);
  }
};

const compareAreaNames = (newArea, oldArea) => {
  // Use Sets to store unique area names
  const newAreaData = new Set(newArea.map((item) => item.areaName));
  const oldAreaData = new Set(oldArea.map((item) => item.areaName));

  if (newAreaData.size !== oldAreaData.size) {
    return true;
  }

  // Compare area names between both sets
  for (let name of newAreaData) {
    if (!oldAreaData.has(name)) {
      return true;
    }
  }
  return false;
};

/**
 * Custom console logger which inherits from window.console
 *
 * @returns Object
 */
const vmsLogger = () => {
  const defaultLogger = {
    log: function log() {},
    warn: function warn() {},
    error: function error() {},
    info: function info() {},
    time: function time() {},
    timeEnd: function timeEnd() {},
    trace: function trace() {},
    table: function table() {},
  };

  // Doing this here allows us to enable/disable the
  // logging at any time.
  const systemConfig = localStorage?.getItem("net.duclo.vms.system");

  if (!systemConfig) {
    return defaultLogger;
  }

  const { init, broadcast, register } = JSON.parse(
    localStorage?.getItem("net.duclo.vms.system")
  );

  if (
    init === false &&
    broadcast === true &&
    typeof register === "string" &&
    parseInt(register.split(".")[2]) === new Date().getDate()
  ) {
    return {
      log: function log() {
        window.vmsConsole?.log.apply(window.vmsConsole, arguments);
        return true;
      },
      error: function error() {
        window.vmsConsole?.error.apply(window.vmsConsole, arguments);
        return true;
      },
      warn: function warn() {
        window.vmsConsole?.warn.apply(window.vmsConsole, arguments);
        return true;
      },
      info: function info() {
        window.vmsConsole?.info.apply(window.vmsConsole, arguments);
        return true;
      },
      time: function time() {
        window.vmsConsole?.time.apply(window.vmsConsole, arguments);
      },
      timeEnd: function timeEnd() {
        window.vmsConsole?.timeEnd.apply(window.vmsConsole, arguments);
        return true;
      },
      trace: function trace() {
        window.vmsConsole?.trace.apply(window.vmsConsole, arguments);
        return true;
      },
      table: function table() {
        window.vmsConsole?.table.apply(window.vmsConsole, arguments);
        return true;
      },
    };
  } else {
    return defaultLogger;
  }
};

const app_version = "WebPortal-1.2.0";
const app_version_date = "Dec 11, 2024";

export {
  areArraysOfObjectsEqual,
  calculateBeforeAndAfterOffsets,
  checkIfMobileView,
  checkObjectEmpty,
  daysToMilliseconds,
  decodeBase64StringToImageFile,
  detectBrowser,
  encodeImageFileToBase64String,
  formatCurrencyWithSmallDecimals,
  formatMACAddress,
  getAccountAvatarURL,
  getAreaName,
  getBeforeAndAfterUnixTimes,
  getCodecAvailability,
  getCurrentTabProperty,
  getCategoryName,
  getCategoryText,
  getCommonColor,
  getDate,
  getDateDifferenceInDays,
  getDateFromEpoch,
  getDetectionValue,
  getDeviceSettingResource,
  getDeviceStatus,
  getDropDownPair,
  getFeatureValue,
  getHourFromEpoch,
  getHoursFromValue,
  getHourPast,
  getHourPastByValue,
  getLicensesExpiring,
  getLocationName,
  getLoggedInUserRole,
  getModalData,
  getMonthFromEpoch,
  getNotificationEventName,
  getOfflineCameras,
  getTotalDevices,
  getGroupedDevices,
  getPastDaysTime,
  getPastDaysInMilliseconds,
  getPastWeekDayName,
  getPropertyShowStatus,
  getPropertyValueIfExist,
  getStartEndMin,
  getTimeFilters,
  getTimeStampFromDate,
  getUnclaimedDevices,
  getUnixDate,
  getWeekDays,
  getWeekFromEpoch,
  getYearFromEpoch,
  generateUUID,
  getNotificationResources,
  mapUserPolicies,
  replaceStringValues,
  moveArrayPosition,
  requestHeader,
  getModuleStatus,
  CheckLoggedInUserRole,
  CheckBreadcrumbForUserRole,
  fetchDateInUnix,
  getManufacturer,
  checkNotificationSupport,
  isNullString,
  getTotalParentDevices,
  getTotalChildDevices,
  app_version,
  app_version_date,
  amplitudeInit,
  amplitudeTrack,
  compareObjectLists,
  findTimeZoneFromSelectedValue,
  compareAreaNames,
  vmsLogger,
};
